"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEffectSkipFirst = void 0;
var _react = require("react");
const useEffectSkipFirst = (val, onChange) => {
  const isFirstRun = (0, _react.useRef)(true);
  (0, _react.useEffect)(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    onChange(val);
  }, Array.isArray(val) ? val : [val]);
};
exports.useEffectSkipFirst = useEffectSkipFirst;