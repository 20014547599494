export const tailwindToSpacerMap = {
  '0rem': {
    top: 'lg:mt-0',
    topMobile: 'mt-0',
    right: 'lg:mr-0',
    rightMobile: 'mr-0',
    bottom: 'lg:mb-0',
    bottomMobile: 'mb-0',
    left: 'lg:ml-0',
    leftMobile: 'ml-0'
  },
  '0.25rem': {
    top: 'lg:mt-1',
    topMobile: 'mt-1',
    right: 'lg:mr-1',
    rightMobile: 'mr-1',
    bottom: 'lg:mb-1',
    bottomMobile: 'mb-1',
    left: 'lg:ml-1',
    leftMobile: 'ml-1'
  },
  '0.5rem': {
    top: 'lg:mt-2',
    topMobile: 'mt-2',
    right: 'lg:mr-2',
    rightMobile: 'mr-2',
    bottom: 'lg:mb-2',
    bottomMobile: 'mb-2',
    left: 'lg:ml-2',
    leftMobile: 'ml-2'
  },
  '0.75rem': {
    top: 'lg:mt-3',
    topMobile: 'mt-3',
    right: 'lg:mr-3',
    rightMobile: 'mr-3',
    bottom: 'lg:mb-3',
    bottomMobile: 'mb-3',
    left: 'lg:ml-3',
    leftMobile: 'ml-3'
  },
  '1rem': {
    top: 'lg:mt-4',
    topMobile: 'mt-4',
    right: 'lg:mr-4',
    rightMobile: 'mr-4',
    bottom: 'lg:mb-4',
    bottomMobile: 'mb-4',
    left: 'lg:ml-4',
    leftMobile: 'ml-4'
  },
  '1.25rem': {
    top: 'lg:mt-5',
    topMobile: 'mt-5',
    right: 'lg:mr-5',
    rightMobile: 'mr-5',
    bottom: 'lg:mb-5',
    bottomMobile: 'mb-5',
    left: 'lg:ml-5',
    leftMobile: 'ml-5'
  },
  '1.5rem': {
    top: 'lg:mt-6',
    topMobile: 'mt-6',
    right: 'lg:mr-6',
    rightMobile: 'mr-6',
    bottom: 'lg:mb-6',
    bottomMobile: 'mb-6',
    left: 'lg:ml-6',
    leftMobile: 'ml-6'
  },
  '1.75rem': {
    top: 'lg:mt-7',
    topMobile: 'mt-7',
    right: 'lg:mr-7',
    rightMobile: 'mr-7',
    bottom: 'lg:mb-7',
    bottomMobile: 'mb-7',
    left: 'lg:ml-7',
    leftMobile: 'ml-7'
  },
  '2rem': {
    top: 'lg:mt-8',
    topMobile: 'mt-8',
    right: 'lg:mr-8',
    rightMobile: 'mr-8',
    bottom: 'lg:mb-8',
    bottomMobile: 'mb-8',
    left: 'lg:ml-8',
    leftMobile: 'ml-8'
  },
  '2.25rem': {
    top: 'lg:mt-9',
    topMobile: 'mt-9',
    right: 'lg:mr-9',
    rightMobile: 'mr-9',
    bottom: 'lg:mb-9',
    bottomMobile: 'mb-9',
    left: 'lg:ml-9',
    leftMobile: 'ml-9'
  },
  '2.5rem': {
    top: 'lg:mt-10',
    topMobile: 'mt-10',
    right: 'lg:mr-10',
    rightMobile: 'mr-10',
    bottom: 'lg:mb-10',
    bottomMobile: 'mb-10',
    left: 'lg:ml-10',
    leftMobile: 'ml-10'
  },
  '4rem': {
    top: 'lg:mt-16',
    topMobile: 'mt-16',
    right: 'lg:mr-16',
    rightMobile: 'mr-16',
    bottom: 'lg:mb-16',
    bottomMobile: 'mb-16',
    left: 'lg:ml-16',
    leftMobile: 'ml-16'
  },
  '5rem': {
    top: 'lg:mt-20',
    topMobile: 'mt-20',
    right: 'lg:mr-20',
    rightMobile: 'mr-20',
    bottom: 'lg:mb-20',
    bottomMobile: 'mb-20',
    left: 'lg:ml-20',
    leftMobile: 'ml-20'
  },
  '6rem': {
    top: 'lg:mt-24',
    topMobile: 'mt-24',
    right: 'lg:mr-24',
    rightMobile: 'mr-24',
    bottom: 'lg:mb-24',
    bottomMobile: 'mb-24',
    left: 'lg:ml-24',
    leftMobile: 'ml-24'
  },
  '7rem': {
    top: 'lg:mt-28',
    topMobile: 'mt-28',
    right: 'lg:mr-28',
    rightMobile: 'mr-28',
    bottom: 'lg:mb-28',
    bottomMobile: 'mb-28',
    left: 'lg:ml-28',
    leftMobile: 'ml-28'
  },
  '8rem': {
    top: 'lg:mt-32',
    topMobile: 'mt-32',
    right: 'lg:mr-32',
    rightMobile: 'mr-32',
    bottom: 'lg:mb-32',
    bottomMobile: 'mb-32',
    left: 'lg:ml-32',
    leftMobile: 'ml-32'
  }
}
