"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCurrentSite = void 0;
var _reactQuery = require("@tanstack/react-query");
var _ = require("../..");
const useCurrentSite = () => {
  const api = (0, _.useCommerceAPI)();
  const params = {
    parameters: {},
    headers: {}
  };
  return (0, _reactQuery.useQuery)(['scapiCurrentSite', params], () => api.sitesApi.current(params));
};
exports.useCurrentSite = useCurrentSite;