"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _IntegrationsProvider = require("../providers/IntegrationsProvider");
const useSfraApi = () => {
  return _react.default.useContext(_IntegrationsProvider.ApiContext);
};
var _default = exports.default = useSfraApi;