import React from 'react'
import { Region } from 'msp-integrations'
import { tailwindToSpacerMap } from './Spacer.utils'

type SpacerProps = {
  spacerTop?: string
  spacerTopMobile?: string
  spacerRight?: string
  spacerRightMobile?: string
  spacerBottom?: string
  spacerBottomMobile?: string
  spacerLeft?: string
  spacerLeftMobile?: string
  displayBlock?: boolean
  content?: any
}

export const Spacer = ({
  spacerTop = '0rem',
  spacerTopMobile = '0rem',
  spacerRight = '0rem',
  spacerRightMobile = '0rem',
  spacerBottom = '0rem',
  spacerBottomMobile = '0rem',
  spacerLeft = '0rem',
  spacerLeftMobile = '0rem',
  displayBlock = false,
  content
}: SpacerProps) => {
  const mt = tailwindToSpacerMap[spacerTop].top
  const mtMobile = tailwindToSpacerMap[spacerTopMobile].topMobile
  const mr = tailwindToSpacerMap[spacerRight].right
  const mrMobile = tailwindToSpacerMap[spacerRightMobile].rightMobile
  const mb = tailwindToSpacerMap[spacerBottom].bottom
  const mbMobile = tailwindToSpacerMap[spacerBottomMobile].bottomMobile
  const ml = tailwindToSpacerMap[spacerLeft].left
  const mlMobile = tailwindToSpacerMap[spacerLeftMobile].leftMobile

  const spacerDisplay = displayBlock ? 'block' : 'inline-flex'

  return (
    content && (
      <div
        className={`${mtMobile} ${mt} ${mrMobile} ${mr} ${mbMobile} ${mb} ${mlMobile} ${ml} ${spacerDisplay} w-fit`}
      >
        <Region region={content} />
      </div>
    )
  )
}

export default Spacer
