"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEventItemsFromBasket = exports.getEventItemFromProduct = void 0;
const getEventItemsFromBasket = basket => {
  var _basket$productItems, _basket$giftCertifica;
  const basketItems = [];
  basket === null || basket === void 0 ? void 0 : (_basket$productItems = basket.productItems) === null || _basket$productItems === void 0 ? void 0 : _basket$productItems.map(item => {
    basketItems.push({
      item_id: item === null || item === void 0 ? void 0 : item.productId,
      item_name: item === null || item === void 0 ? void 0 : item.productName,
      item_category: item === null || item === void 0 ? void 0 : item.c_primaryCategoryId,
      item_price: (item === null || item === void 0 ? void 0 : item.priceAfterOrderDiscount) || (item === null || item === void 0 ? void 0 : item.price),
      quantity: item === null || item === void 0 ? void 0 : item.quantity
    });
  });
  basket === null || basket === void 0 ? void 0 : (_basket$giftCertifica = basket.giftCertificateItems) === null || _basket$giftCertifica === void 0 ? void 0 : _basket$giftCertifica.map(item => {
    basketItems.push({
      item_id: item === null || item === void 0 ? void 0 : item.c_originalSKU,
      item_name: item === null || item === void 0 ? void 0 : item.c_productName,
      item_category: item === null || item === void 0 ? void 0 : item.c_primaryCategoryId,
      item_price: item === null || item === void 0 ? void 0 : item.amount,
      quantity: 1
    });
  });
  return basketItems;
};
exports.getEventItemsFromBasket = getEventItemsFromBasket;
const getEventItemFromProduct = (product, quantity) => {
  return {
    item_id: (product === null || product === void 0 ? void 0 : product.productId) || (product === null || product === void 0 ? void 0 : product.id),
    item_name: (product === null || product === void 0 ? void 0 : product.productName) || (product === null || product === void 0 ? void 0 : product.name),
    item_category: (product === null || product === void 0 ? void 0 : product.c_primaryCategoryId) || (product === null || product === void 0 ? void 0 : product.primaryCategoryId),
    item_price: (product === null || product === void 0 ? void 0 : product.c_priceDiscounted) || (product === null || product === void 0 ? void 0 : product.price),
    quantity: quantity || (product === null || product === void 0 ? void 0 : product.quantity)
  };
};
exports.getEventItemFromProduct = getEventItemFromProduct;