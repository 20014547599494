"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_SEARCH_PARAMS = exports.DEFAULT_LIMIT_VALUES = exports.CAT_MENU_STALE_TIME = exports.ALL_SALE_CATEGORY_ID = void 0;
// Constants used in the used for product searching.
const DEFAULT_SEARCH_PARAMS = exports.DEFAULT_SEARCH_PARAMS = {
  limit: 25,
  offset: 0,
  sort: 'best-matches',
  refine: []
};
const DEFAULT_LIMIT_VALUES = exports.DEFAULT_LIMIT_VALUES = [25, 50, 100]; // Page sizes

// Constants for menu
const CAT_MENU_STALE_TIME = exports.CAT_MENU_STALE_TIME = 10000; // 10 seconds

const ALL_SALE_CATEGORY_ID = exports.ALL_SALE_CATEGORY_ID = 'all-sale';