"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
class SfraApiPages {
  constructor(fetch, componentMapping) {
    this._fetch = fetch;
    this._componentMapping = componentMapping;
  }
  getPage(args) {
    var _this = this;
    return (0, _asyncToGenerator2.default)(function* () {
      return _this._fetch('ContentServices-Get', 'GET', args, 'GetPage');
    })();
  }
  getComponentMapping() {
    return this._componentMapping;
  }
}
var _default = exports.default = SfraApiPages;