"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.submitCreditCardForm = exports.getIframeOrigin = exports.getCreditCardCustomerValues = void 0;
const getIframeOrigin = iframeUrl => {
  const urlArray = iframeUrl.split('/');
  const secondArr = urlArray[2].split('@');
  let hostname = '';
  if (secondArr.length > 1) {
    hostname = secondArr[1];
  } else {
    hostname = urlArray[2];
  }
  return `${urlArray[0]}//${hostname}`;
};
exports.getIframeOrigin = getIframeOrigin;
const getCreditCardCustomerValues = (basket, customer) => {
  const {
    billingAddress = {}
  } = basket;
  return {
    'name.given': billingAddress.firstName || '',
    'name.family': billingAddress.lastName || '',
    'address.street': billingAddress.address1 || '',
    'address.city': billingAddress.city || '',
    'address.zip': billingAddress.postalCode || '',
    'address.country': billingAddress.countryCode || '',
    'contact.email': customer.email || ''
  };
};
exports.getCreditCardCustomerValues = getCreditCardCustomerValues;
const submitCreditCardForm = (iframeEl, customerValues, targetOrigin) => new Promise((resolve, reject) => {
  var _iframeEl$contentWind;
  window.addEventListener('message', message => {
    if (message.origin !== window.origin) {
      let messageData;
      try {
        messageData = JSON.parse(message.data);
      } catch {
        reject(new Error('HEIDELPAY_CREDIT_CARD - Payment result could not be parsed'));
        return;
      }
      if (messageData['PROCESSING.RESULT'] === 'NOK') {
        reject(new Error('HEIDELPAY_CREDIT_CARD - PROCESSING.RESULT = NOK'));
      }
      if (messageData['PROCESSING.STATUS'] !== 'REJECTED_VALIDATION' && messageData['PROCESSING.RESULT'] !== 'NOK') {
        resolve({
          cardType: messageData['ACCOUNT.BRAND'],
          expirationMonth: parseInt(messageData['ACCOUNT.EXPIRY_MONTH'], 10),
          expirationYear: parseInt(messageData['ACCOUNT.EXPIRY_YEAR'], 10),
          holder: messageData['ACCOUNT.HOLDER'],
          number: messageData['ACCOUNT.NUMBER']
        });
      }
    }
  });
  (_iframeEl$contentWind = iframeEl.contentWindow) === null || _iframeEl$contentWind === void 0 ? void 0 : _iframeEl$contentWind.postMessage(JSON.stringify(customerValues), targetOrigin);
});
exports.submitCreditCardForm = submitCreditCardForm;