import React from 'react'
import { IconsProps } from 'msp-integrations'

const YoutubeIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      viewBox='0 0 22 15'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Youtube</title>
      <g transform="translate(.608)" fill="none"><mask id="a" fill="#fff"><use /></mask><g /><path d="M19.887 11.863c-.203 1.347-.765 1.644-1.479 1.893-.529.185-3.833.382-7.908.382s-7.38-.197-7.908-.382c-.714-.249-1.276-.546-1.479-1.893C.943 10.733.844 9.142.844 7.5c0-1.642.098-3.232.269-4.363.203-1.347.765-1.644 1.479-1.893C3.12 1.059 6.425.862 10.5.862s7.38.197 7.908.382c.714.249 1.276.546 1.479 1.893.17 1.131.269 2.721.269 4.363 0 1.642-.098 3.232-.269 4.363m.834-8.857c-.278-1.846-1.242-2.3-2.04-2.578S13.924 0 10.5 0C7.076 0 3.117.15 2.319.428 1.52.707.557 1.16.279 3.006 0 4.85 0 7.095 0 7.5c0 .405 0 2.65.279 4.495.278 1.845 1.242 2.298 2.04 2.577.798.278 4.757.428 8.181.428 3.424 0 7.383-.15 8.181-.428.798-.279 1.762-.732 2.04-2.577C21 10.149 21 7.905 21 7.5c0-.405 0-2.65-.279-4.494" fill="#FFF" /><path fill="#FFF" d="M8.372 4.25v6.46l5.508-3.23z" /></g>
    </svg>
  )
}

export default YoutubeIcon
