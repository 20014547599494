"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Locales = void 0;
/**
 * Class to request custom SFRA locales endpoint
 */
class Locales {
  /**
   * Create an instance of the API with the given config.
   * @param {ClientConfig} config - The config used to instantiate SDK apis.
   */
  constructor(config, fetch) {
    this._config = config;
    this._fetch = fetch;
  }
  getLocales() {
    return this._fetch(`Site-Countries`, 'get', 'GetLocales');
  }
}
exports.Locales = Locales;