import React from 'react'
import { defineMessage } from 'react-intl'
import { getConfig as getConfigMSP, Icons } from 'msp-components'
import JasminSVGMapping from './JasminSVGMapping'

const tenantId = 'jasmin'

const getConfig = (ssrConfig?: any) =>
  getConfigMSP(
    {
      title: 'Avoury',
      titleNewsletter: 'Avoury®',
      tenantId,
      cookiePrefix: 'Avoury',
      gtmDomain: 'www.googletagmanager.com',
      theme: {
        iconMapping: JasminSVGMapping,
        font: {
          regular: 'font-Roboto',
          medium: 'font-Roboto font-medium',
          bold: 'font-Roboto font-bold',
          wide: 'font-Roboto'
        },
        fontVariant: {
          regular: 'font-Lora',
          medium: 'font-Lora font-bold',
          bold: 'font-Lora font-bold',
          wide: 'font-Lora'
        },
        mspComponents: {
          input: {
            default: {
              div: 'text-machine2-100 focus-within:text-darkGray focus-within:stroke-darkGray',
              div_Dark:
                'text-white focus-within:text-white focus-within:stroke-white',
              label: 'group-hover:text-darkGray',
              label_Dark: 'text-machine2-75 group-hover:text-white',
              labelDisabled: 'group-hover:text-machine2-75',
              labelDarkDisabled: 'text-darkGray group-hover:text-darkGray',
              input:
                'text-darkGray border-machine2-75 group-hover:border-darkGray placeholder-machine2-75 placeholder:tracking-0.2 focus:border-darkGray focus:ring-platinaGreen',
              input_Dark:
                'text-white bg-black border-white placeholder-mediumGray placeholder:tracking-0.2 focus:ring-platinaGreen disabled:border-darkGray disabled:placeholder-darkGray disabled:bg-black disabled:group-hover:border-darkGray',
              passwordIcon: 'group-hover:fill-darkGray',
              passwordIconDark: 'group-hover:fill-white'
            },
            underline: {
              input:
                'text-black border-black placeholder-machine2-100 placeholder:tracking-0 disabled:placeholder:text-machine2-75',
              input_Dark:
                'text-white border-white placeholder-mediumGray disabled:placeholder:text-darkGray disabled:border-darkGray',
              passwordIcon: 'fill-black',
              passwordIconDark: 'fill-white'
            },
            textColor: 'text-machine2-100'
          },
          button: {
            layoutColors: {
              getColors: (darkmode) => ({
                default: `bg-black fill-white appearance-none text-white border focus:ring focus:ring-platinaGreen ${
                  darkmode ? 'border-white' : 'border-black'
                }`,
                outline: `bg-white fill-black appearance-none text-black border focus:ring focus:ring-platinaGreen ${
                  darkmode ? 'border-white' : 'border-black'
                }`,
                danger:
                  'bg-white fill-errorRed text-errorRed border border-errorRed hover:border-black',
                square: `bg-black fill-white stroke-white text-white border focus:ring focus:ring-platinaGreen ${
                  darkmode ? 'border-white' : 'border-black'
                }`
              })
            },
            backgroundTransitionColors: {
              default: 'before:bg-white',
              outline: 'before:bg-black',
              danger: 'before:bg-black',
              square: 'before:bg-white'
            },
            contentColors: {
              default:
                'md:group-hover:text-black md:group-hover:fill-black transition-colors',
              outline:
                'md:group-hover:text-white md:group-hover:fill-white transition-colors',
              square:
                'md:group-hover:text-black md:group-hover:fill-black md:group-hover:stroke-black transition-colors'
            }
          },
          checkbox: {
            getStyles: (mode: 'dark' | 'light' | undefined) => {
              const defaultMode = mode !== 'dark' && mode !== 'light'
              return {
                labelStyle: 'text-14 leading-16 font-Roboto tracking-0.2',
                enabledCheckStyle: `fill-white ${
                  defaultMode ? 'border-black' : 'border-white'
                }`,
                disabledCheckStyle: `border-machine2-75 fill-white ${
                  defaultMode ? 'bg-machine2-100' : 'bg-machine2-50'
                }`,
                valueTrueStyle: 'bg-black'
              }
            }
          },
          radiobutton: {
            getStyles: (darkmode) => ({
              label: `text-14 leading-16 my-2 inline-flex w-fit cursor-pointer items-center tracking-[0.2px] ${
                darkmode ? 'text-white' : 'text-black'
              }`,
              labelWrapper: 'm-0 h-fit',
              initial: `font-Roboto bg-white ${
                darkmode ? 'border-white' : 'border-black'
              }`,
              checked: `checked:bg-white checked:after:border-black ${
                darkmode &&
                'checked:after:top-0 checked:after:left-0 checked:after:h-[18px] checked:after:min-w-[18px] checked:after:rounded-[18px]'
              }`,
              disabled: 'disabled:bg-machine2-50 disabled:border-machine2-75',
              labelValueVariant: 'font-Roboto text-16 mt-2',
              labelWrapperValueVariant:
                'h-9 w-fit px-5 py-2 border border-black rounded-md cursor-pointer'
            })
          },
          spinner: {
            position: 'bottom-0 left-0 right-0 top-0',
            fullscreenContainer: {
              dark: 'bg-black bg-opacity-70',
              light: 'bg-white'
            },
            label: {
              dark: 'text-white',
              light: 'text-black'
            },
            content: (
              <>
                <div className='relative -top-1 left-0 z-10 h-auto w-auto fill-white'>
                  <Icons
                    className=''
                    iconName='AIcon'
                    width='50px'
                    height='50px'
                  />
                </div>
                <div className='absolute h-24 w-24 animate-spin rounded-full bg-gradient-to-r from-white to-transparent p-1'>
                  <div className='h-full w-full rounded-full bg-black' />
                </div>
              </>
            )
          },
          requestPasswordModal: {
            header: 'tracking-0',
            detail: 'tracking-0'
          },
          loginForm: { tracking: 'tracking-0', fontSize: 'text-14' },
          accountDataSharing: 'xl:text-16',
          headlineSubline: 'tracking-0 md:tracking-0',
          footerCheckout: 'tracking-0',
          footer: {
            link: 'tracking-0',
            gridWrapperStyles: 'tracking-0',
            priceInfoWrapper: 'tracking-0',
            icon: 'JasminLogo',
            iconWidth: '190px'
          },
          pageActionPlaceHolder: {
            Wrapper:
              'flex flex-col w-full bg-lightGray justify-center items-center px-4 py-8',
            IconWrapper: 'text-grayRegular',
            Headline: 'font-Roboto text-16 pt-4 pb-2 text-black',
            ContentText: 'font-Roboto text-14 text-black',
            ActionsWrapper: 'pt-6'
          },
          wishlistButton:
            'bg-green border-none cursor-default fill-black stroke-none focus:ring-0 focus:outline-none focus:shadow-none',
          headlineFont: 'font-bold',
          newsletterForm: 'tracking-0',
          newsletterModal: {
            title: 'leading-12 tracking-0',
            success: 'tracking-0'
          },
          contactForm: {
            reasonFieldName: '00N1t00000LUmnD',
            reasons: [
              {
                id: 'Produktinformationen',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.product_information',
                  defaultMessage: 'Product information'
                })
              },
              {
                id: 'Bestellung und Lieferung',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.orders_and_delivery',
                  defaultMessage: 'Orders and Delivery'
                })
              },
              {
                id: 'Technischer Kundendienst',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.technical_support',
                  defaultMessage: 'Technical customer service'
                })
              },
              {
                id: 'Marke und Promotions',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.brand_and_promotions',
                  defaultMessage: 'Brand and promotions'
                })
              },
              {
                id: 'Kundenkonto',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.customer_account',
                  defaultMessage: 'Customer account'
                })
              },
              {
                id: 'Allgemeine Informationen / Serviceanfrage',
                value: defineMessage({
                  id: 'msp.contact_form.reason.options.general',
                  defaultMessage: 'General information / Service request'
                })
              }
            ]
          },
          topNewsletterBanner: {
            getBgColor: (backgroundColor = 'jasmin_lightGreen') => {
              const colors = {
                jasmin_lightGreen: 'bg-platinaGreen',
                jasmin_darkGreen: 'bg-pineGreen',
                jasmin_red: 'bg-teaFruit',
                jasmin_lightBrown: 'bg-creamGreige'
              }

              return colors[backgroundColor]
            },
            getIcon: (backgroundColor = 'jasmin_lightGreen') => {
              const icons = {
                jasmin_lightGreen: 'fill-platinaGreen',
                jasmin_darkGreen: 'fill-pineGreen',
                jasmin_red: 'fill-teaFruit'
              }

              return icons[backgroundColor]
            },
            getColor: (backgroundColor = 'jasmin_lightGreen') => {
              const colors = {
                jasmin_lightGreen: 'placeholder-white border-white text-white',
                jasmin_darkGreen: 'placeholder-white border-white text-white',
                jasmin_red: 'placeholder-white border-white text-white'
              }
              return colors[backgroundColor]
            },
            getErrorColor: (backgroundColor = 'jasmin_lightGreen') => {
              const colors = {
                jasmin_lightGreen:
                  'border-errorRed text-errorRed group-hover:text-errorRed focus:border-errorRed placeholder-errorRed',
                jasmin_darkGreen:
                  'border-errorRed text-errorRed group-hover:text-errorRed focus:border-errorRed placeholder-errorRed',
                jasmin_red:
                  'border-errorPink text-errorPink group-hover:text-errorPink focus:border-errorPink placeholder-errorPink'
              }
              return colors[backgroundColor]
            }
          },
          progressBar: {
            containerBgColor: 'text-pineGreen bg-greige',
            barColor: 'bg-pineGreen'
          },
          cartPromotionSlot: {
            getBgColor: (backgroundColor = 'jasmin_lightGreen') => {
              const colors = {
                jasmin_lightGreen: 'bg-green',
                jasmin_darkGreen: 'bg-pineGreen',
                jasmin_red: 'bg-teaFruit',
                jasmin_lightBrown: 'bg-creamGreige'
              }

              return colors[backgroundColor]
            }
          }
        }
      },
      info: {
        email: 'service@avoury.com',
        brand: 'Avoury. The Tea.',
        url: 'https://www.avoury.com',
        team: 'Avoury TEAm',
        supportEmail: 'support@avoury.com',
        tel: '00800 7000 6000'
      }
    },
    ssrConfig
  )
export default getConfig
