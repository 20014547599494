import React from 'react'
import { useCookie } from 'msp-integrations'
import { Icons } from 'msp-components'
import { IconButtonProps } from './IconButton.types'

const IconButton = ({
  className,
  onClick,
  iconName,
  height,
  width,
  color,
  event_name = '',
  event_action = ''
}: IconButtonProps) => {
  const { eventTracking } = useCookie()
  const handleClick = () => {
    if (event_name && event_action) {
      eventTracking(event_name, { event_action: event_action })
    }
    onClick && onClick()
  }

  return (
    <div
      className={`flex cursor-pointer items-center ${className}`}
      onClick={() => handleClick()}
    >
      <Icons iconName={iconName} height={height} width={width} color={color} />
    </div>
  )
}

export default IconButton
