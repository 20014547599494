"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useQuery = void 0;
var _reactQuery = require("@tanstack/react-query");
var _useClient = _interopRequireDefault(require("./useClient"));
const useQuery = (queryKey, fn, queryOptions) => {
  return (0, _reactQuery.useQuery)(queryKey, (0, _useClient.default)(fn), queryOptions);
};
exports.useQuery = useQuery;