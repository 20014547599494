import React from 'react'
import { IconsProps } from 'msp-integrations'

const InstagramIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 141.73 141.73'
    >
      <path d="M70.87 31.24c-10.76 0-12.11.05-16.34.24-4.22.19-7.1.86-9.62 1.84-2.61 1.01-4.82 2.37-7.02 4.57-2.2 2.2-3.56 4.41-4.57 7.02-.98 2.52-1.65 5.4-1.84 9.62-.19 4.23-.24 5.58-.24 16.34s.05 12.11.24 16.34c.19 4.22.86 7.1 1.84 9.62 1.01 2.61 2.37 4.82 4.57 7.02 2.2 2.2 4.41 3.56 7.02 4.57 2.52.98 5.4 1.65 9.62 1.84 4.23.19 5.58.24 16.34.24s12.11-.05 16.34-.24c4.22-.19 7.1-.86 9.62-1.84 2.61-1.01 4.82-2.37 7.02-4.57 2.2-2.2 3.56-4.41 4.57-7.02.98-2.52 1.65-5.4 1.84-9.62.19-4.23.24-5.58.24-16.34s-.05-12.11-.24-16.34c-.19-4.22-.86-7.1-1.84-9.62-1.01-2.61-2.37-4.82-4.57-7.02-2.2-2.2-4.41-3.56-7.02-4.57-2.52-.98-5.4-1.65-9.62-1.84-4.23-.19-5.58-.24-16.34-.24m0 7.14c10.58 0 11.83.04 16.01.23 3.86.18 5.96.82 7.36 1.36 1.85.72 3.17 1.58 4.56 2.96 1.39 1.39 2.25 2.71 2.96 4.56.54 1.4 1.19 3.49 1.36 7.36.19 4.18.23 5.43.23 16.01 0 10.58-.04 11.83-.23 16.01-.18 3.86-.82 5.96-1.36 7.36-.72 1.85-1.58 3.17-2.96 4.56-1.39 1.39-2.71 2.25-4.56 2.96-1.4.54-3.49 1.19-7.36 1.36-4.18.19-5.43.23-16.01.23s-11.83-.04-16.01-.23c-3.86-.18-5.96-.82-7.36-1.36-1.85-.72-3.17-1.58-4.56-2.96-1.39-1.39-2.25-2.71-2.96-4.56-.54-1.4-1.19-3.49-1.36-7.36-.19-4.18-.23-5.43-.23-16.01 0-10.58.04-11.83.23-16.01.18-3.86.82-5.96 1.36-7.36.72-1.85 1.58-3.17 2.96-4.56 1.39-1.39 2.71-2.25 4.56-2.96 1.4-.54 3.49-1.19 7.36-1.36 4.17-.19 5.43-.23 16.01-.23" /><path d="M70.87 84.07c-7.29 0-13.21-5.91-13.21-13.21 0-7.29 5.91-13.21 13.21-13.21s13.21 5.91 13.21 13.21c-.01 7.3-5.92 13.21-13.21 13.21m0-33.55c-11.24 0-20.35 9.11-20.35 20.35 0 11.24 9.11 20.35 20.35 20.35s20.35-9.11 20.35-20.35c-.01-11.24-9.12-20.35-20.35-20.35m25.9-.81c0 2.63-2.13 4.75-4.75 4.75-2.63 0-4.75-2.13-4.75-4.75 0-2.63 2.13-4.75 4.75-4.75s4.75 2.13 4.75 4.75" />
    </svg>
  )
}

export default InstagramIcon
