import React from 'react'
import { IconsProps } from 'msp-integrations'

const CloseIcon = (props: IconsProps) => {
  return (
    <svg {...props} viewBox="0 0 16 16">
      <path d="m6.513 7.947-4.94 4.94L2.987 14.3l4.94-4.94 5.013 5.013 1.414-1.414L9.34 7.947l4.96-4.96-1.414-1.414-4.96 4.96L3.04 1.646 1.626 3.06l4.887 4.887z"/>
    </svg>
  )
}

export default CloseIcon
