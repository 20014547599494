"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validatePostalCode = void 0;
var _useCountryData = require("../hooks/useCountryData");
/**
 * @param address
 * @param i18n
 * @return boolean | undefined
 */
const validatePostalCode = (address, i18n) => {
  if (address.countryCode && address.postalCode) {
    const postalCodeRegex = (0, _useCountryData.GetPostalCodeRegexByCountryCode)(i18n, address.countryCode);
    if (postalCodeRegex) {
      const regex = new RegExp(postalCodeRegex, 'i');
      return regex.test(address.postalCode);
    }
  }
  return undefined;
};
exports.validatePostalCode = validatePostalCode;