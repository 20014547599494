"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseSlasJWT = exports.getUrl = exports.getShopperConfig = exports.getBasicAuth = exports.errorHandler = void 0;
var _jwtDecode = _interopRequireDefault(require("jwt-decode"));
const getUrl = (param, config) => `https://${config === null || config === void 0 ? void 0 : config.shortCode}.api.commercecloud.salesforce.com/shopper/${param}/v1/organizations/${config === null || config === void 0 ? void 0 : config.organizationId}/`;
exports.getUrl = getUrl;
const getBasicAuth = config => `Basic ${btoa(`${config === null || config === void 0 ? void 0 : config.clientId}:${config === null || config === void 0 ? void 0 : config.secret}`)}`;
exports.getBasicAuth = getBasicAuth;
const errorHandler = config => (err, req, res, next) => {
  const statusCode = res.statusCode ? res.statusCode : 500;
  res.status(statusCode);
  res.json({
    message: err.message,
    stack: config !== null && config !== void 0 && config.isProduction ? null : err.stack
  });
};
exports.errorHandler = errorHandler;
const parseSlasJWT = jwt => {
  const payload = (0, _jwtDecode.default)(jwt);
  const {
    sub,
    isb,
    exp
  } = payload;
  if (!sub || !isb) {
    throw new Error('Unable to parse access token payload: missing sub and isb.');
  }

  // ISB format
  // 'uido:ecom::upn:Guest||xxxEmailxxx::uidn:FirstName LastName::gcid:xxxGuestCustomerIdxxx::rcid:xxxRegisteredCustomerIdxxx::chid:xxxSiteIdxxx',
  const isbParts = isb.split('::');
  const isGuest = isbParts[1] === 'upn:Guest';
  const customerId = isGuest ? isbParts[3].replace('gcid:', '') : isbParts[4].replace('rcid:', '');
  // SUB format
  // cc-slas::zzrf_001::scid:c9c45bfd-0ed3-4aa2-xxxx-40f88962b836::usid:b4865233-de92-4039-xxxx-aa2dfc8c1ea5
  const usid = sub.split('::')[3].replace('usid:', '');
  return {
    isGuest,
    customerId,
    usid,
    exp
  };
};
exports.parseSlasJWT = parseSlasJWT;
const getShopperConfig = config => {
  var _config$app, _config$app$shopper, _config$app2, _config$app2$shopper, _config$app3, _config$app3$commerce, _config$app3$commerce2, _config$app4, _config$app4$commerce, _config$app4$commerce2, _config$app5, _config$app5$einstein;
  return {
    clientId: config === null || config === void 0 ? void 0 : (_config$app = config.app) === null || _config$app === void 0 ? void 0 : (_config$app$shopper = _config$app.shopper) === null || _config$app$shopper === void 0 ? void 0 : _config$app$shopper.clientId,
    secret: config === null || config === void 0 ? void 0 : (_config$app2 = config.app) === null || _config$app2 === void 0 ? void 0 : (_config$app2$shopper = _config$app2.shopper) === null || _config$app2$shopper === void 0 ? void 0 : _config$app2$shopper.secret,
    shortCode: config === null || config === void 0 ? void 0 : (_config$app3 = config.app) === null || _config$app3 === void 0 ? void 0 : (_config$app3$commerce = _config$app3.commerceAPI) === null || _config$app3$commerce === void 0 ? void 0 : (_config$app3$commerce2 = _config$app3$commerce.parameters) === null || _config$app3$commerce2 === void 0 ? void 0 : _config$app3$commerce2.shortCode,
    organizationId: config === null || config === void 0 ? void 0 : (_config$app4 = config.app) === null || _config$app4 === void 0 ? void 0 : (_config$app4$commerce = _config$app4.commerceAPI) === null || _config$app4$commerce === void 0 ? void 0 : (_config$app4$commerce2 = _config$app4$commerce.parameters) === null || _config$app4$commerce2 === void 0 ? void 0 : _config$app4$commerce2.organizationId,
    isProduction: config === null || config === void 0 ? void 0 : (_config$app5 = config.app) === null || _config$app5 === void 0 ? void 0 : (_config$app5$einstein = _config$app5.einsteinAPI) === null || _config$app5$einstein === void 0 ? void 0 : _config$app5$einstein.isProduction
  };
};
exports.getShopperConfig = getShopperConfig;