"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSfraApiGiftCertRedeem = useSfraApiGiftCertRedeem;
exports.useSfraApiGiftCertRemove = useSfraApiGiftCertRemove;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _useMutation = require("../../useMutation");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function useSfraApiGiftCertRedeem(args, options) {
  if (!args.query.giftCertID) {
    throw new Error('giftCertID is required for useSfraApiGiftCertRedeem.');
  }
  return (0, _useMutation.useMutation)((_, {
    giftCertificates: service
  }) => service.redeem(args).catch(err => {
    return {
      error: true,
      ErrorMsg: err.ErrorMsg
    };
  }), _objectSpread({}, options));
}
function useSfraApiGiftCertRemove(args, options) {
  if (!args.query.giftCertificateCode) {
    throw new Error('giftCertificateCode is required for useSfraApiGiftCertRemove.');
  }
  return (0, _useMutation.useMutation)((_, {
    giftCertificates: service
  }) => service.remove(args).catch(err => {
    return {
      error: true,
      ErrorMsg: err.ErrorMsg
    };
  }), _objectSpread({}, options));
}