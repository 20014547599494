"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Order = void 0;
/**
 * Class to request custom SFRA Order endpoint
 */
class Order {
  /**
   * Create an instance of the API with the given config.
   * @param {ClientConfig} config - The config used to instantiate SDK apis.
   */
  constructor(config, fetch) {
    this._config = config;
    this._fetch = fetch;
  }
  cancelOrder(args) {
    return this._fetch('Order-Cancel', 'post', args);
  }
  reOrder(args) {
    return this._fetch('Order-Reorder', 'post', args);
  }
}
exports.Order = Order;