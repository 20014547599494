"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useEinstein = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _useEinstein = require("../../hooks/useEinstein");
var _contexts = require("../../contexts");
var _useCookie = require("../../../../hooks/useCookie");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const useEinstein = () => {
  const api = (0, _contexts.useCommerceAPI)();
  const self = (0, _useEinstein.useEinstein)();
  const {
    isMarketingAllowed,
    getCookieFlags
  } = (0, _useCookie.useCookie)();
  return _objectSpread(_objectSpread({}, self), {}, {
    sendViewProduct(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendViewProduct(...args);
      })();
    },
    sendViewSearch(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendViewSearch(...args);
      })();
    },
    sendClickSearch(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendClickSearch(...args);
      })();
    },
    sendViewCategory(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendViewCategory(...args);
      })();
    },
    sendClickCategory(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendClickCategory(...args);
      })();
    },
    sendViewPage(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendViewPage(...args);
      })();
    },
    sendBeginCheckout(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendBeginCheckout(...args);
      })();
    },
    sendCheckoutStep(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendCheckoutStep(...args);
      })();
    },
    sendViewReco(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendViewReco(...args);
      })();
    },
    sendClickReco(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendClickReco(...args);
      })();
    },
    sendAddToCart(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.sendAddToCart(...args);
      })();
    },
    getRecommenders(...args) {
      return (0, _asyncToGenerator2.default)(function* () {
        if (!isMarketingAllowed) return;
        // @ts-ignore
        return api.einstein.getRecommenders(...args);
      })();
    },
    getRecommendations(...args) {
      const {
        isMarketingAllowed
      } = getCookieFlags();
      if (!isMarketingAllowed) return false;
      self.getRecommendations(...args);
      return true;
    },
    getZoneRecommendations(...args) {
      const {
        isMarketingAllowed
      } = getCookieFlags();
      if (!isMarketingAllowed) return false;
      self.getZoneRecommendations(...args);
      return true;
    }
  });
};
exports.useEinstein = useEinstein;