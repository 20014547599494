// Import SVGs
/*
Steps to add new icons
1. Place the svg file in apps/jasmin-frontend/app/assets/svg/icons directory.
2. Translate svg to tsx component.
3. Import the svg file below in this file with sorted order
4. Add the new index in the SVGMappings array
5. And you can call you Icon like this: <Icons color="#1a0dab" iconName="Favorite" size="3xl" />
*/
import SVGMapping from './SVGMapping'
import loadable from '@loadable/component'
import JasminLogoIcon from '@/assets/svg/icons/JasminLogoIcon'
import UserAccountIcon from '@/assets/svg/icons/UserAccountIcon'
import WishlistIcon from '@/assets/svg/icons/WishlistIcon'
import BasketIcon from '@/assets/svg/icons/BasketIcon'
import SearchJasminIcon from '@/assets/svg/icons/SearchJasminIcon'

export const AsyncIcon = (icon: string) =>
  loadable(() => import(`@/assets/svg/icons/${icon}`))

// Exclusive Jasmin Icons
export const JasminIcons = {
  AIcon: AsyncIcon('AIcon'),
  AllergyFree: AsyncIcon('AllergyFreeIcon'),
  Barcode: AsyncIcon('BarcodeIcon'),
  Basket: BasketIcon,
  CaffeineFree: AsyncIcon('CaffeineFreeIcon'),
  Chat: AsyncIcon('ChatIcon'),
  BigCheck: AsyncIcon('CheckIcon'),
  CupWithSteam: AsyncIcon('CupWithSteamIcon'),
  DropEmpty: AsyncIcon('DropEmptyIcon'),
  DropFilled: AsyncIcon('DropFilledIcon'),
  GlutenFree: AsyncIcon('GlutenFreeIcon'),
  JasminLogo: JasminLogoIcon,
  Mail: AsyncIcon('MailIcon'),
  NegativeAttention: AsyncIcon('NegativeAttentionIcon'),
  Newsletter: AsyncIcon('NewsletterIcon'),
  Organic: AsyncIcon('OrganicIcon'),
  Purchase: AsyncIcon('PurchaseIcon'),
  Rainforest: AsyncIcon('RainforestIcon'),
  RainforestApple: AsyncIcon('RainforestAppleIcon'),
  RainforestApple62: AsyncIcon('RainforestApple62Icon'),
  RainforestApple70: AsyncIcon('RainforestApple70Icon'),
  RainforestApple73: AsyncIcon('RainforestApple73Icon'),
  RainforestApple76: AsyncIcon('RainforestApple76Icon'),
  RainforestApple80: AsyncIcon('RainforestApple80Icon'),
  RainforestCamomile: AsyncIcon('RainforestCamomileIcon'),
  RainforestRooibos: AsyncIcon('RainforestRooibosIcon'),
  RainforestTea: AsyncIcon('RainforestTeaIcon'),
  SugarFree: AsyncIcon('SugarFreeIcon'),
  UserAccount: UserAccountIcon,
  Wishlist: WishlistIcon,
  Search: SearchJasminIcon,
  WishlistAdd: AsyncIcon('WishlistAddIcon'),
  UserIcon: AsyncIcon('UserIcon'),
  TimerIcon: AsyncIcon('LeafTimerIcon')
}

// Mapping
const JasminSVGMapping = {
  ...SVGMapping,
  ...JasminIcons
}

export default JasminSVGMapping
