"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useSfraApi = _interopRequireDefault(require("./useSfraApi"));
function useClient(fn) {
  const services = (0, _useSfraApi.default)();
  return variables => {
    return fn(variables, services);
  };
}
var _default = exports.default = useClient;