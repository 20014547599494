"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBraze = void 0;
var _react = require("react");
var _BrazeProvider = require("../providers/BrazeProvider");
const useBraze = () => {
  return (0, _react.useContext)(_BrazeProvider.BrazeContext);
};
exports.useBraze = useBraze;