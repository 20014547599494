"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _query = require("./pages/query");
Object.keys(_query).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _query[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _query[key];
    }
  });
});
var _query2 = require("./gift-certificates/query");
Object.keys(_query2).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _query2[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _query2[key];
    }
  });
});
var _mutation = require("./gift-certificates/mutation");
Object.keys(_mutation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _mutation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _mutation[key];
    }
  });
});