import React from 'react'
import { useForm } from 'react-hook-form'
import { getAssetUrl } from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import type { FieldsConfiguration } from 'msp-components'
import Seo from '@/components/seo'
import { Headline, Grid, Button, Field } from 'msp-components'
import { setCookie } from '@/utils/cookie-utils'

function getFrontendAccessKey(
  username: string,
  password: string,
  salt: string
) {
  const encoder = new TextEncoder()
  const encodedComposition = encoder
    .encode(`${username}:${password}:${salt}`)
    .join('-')

  return btoa(encodedComposition)
}

const AccessForm = ({ accessKey, salt }: any) => {
  const form = useForm()

  const fieldConfig: FieldsConfiguration = {
    username: {
      id: 'username',
      type: 'text',
      name: 'username',
      placeholder: '',
      label: 'Username',
      rules: {
        required: 'Please enter your username.'
      }
    },
    password: {
      id: 'password',
      type: 'password',
      name: 'password',
      placeholder: '',
      label: 'Password',
      rules: {
        required: 'Please enter your password.'
      }
    }
  }

  const submitForm = async (data: any) => {
    const accesKeyToCompare = getFrontendAccessKey(
      data.username,
      data.password,
      salt
    )

    if (accessKey !== accesKeyToCompare) {
      form.setError('username', {
        type: 'custom',
        message: 'Username or password combination is wrong'
      })
      form.setError('password', {
        type: 'custom',
        message: 'Username or password combination is wrong'
      })
    } else {
      form.clearErrors()

      setCookie('accessKey', accesKeyToCompare, 7)

      window.location.reload()
    }
  }

  return (
    <div
      id='frontend-access-form'
      className='container mx-auto mt-12 px-4 md:px-32 lg:px-48'
    >
      <Seo title='Frontend Access Restriction'>
        <link rel='stylesheet' href={getAssetUrl('static/css/styles.css')} />
      </Seo>
      <Grid wrapperStyles='grid-cols-1'>
        <Headline className='text-24 mb-8'>
          Frontend Access Restriction
        </Headline>
        <form
          onSubmit={form.handleSubmit(submitForm)}
          id={'frontend-access-form'}
          data-testid={'frontend-access-form-test'}
        >
          <Field
            className='w-full'
            control={form.control}
            config={fieldConfig.username}
            error={form.formState.errors?.username}
            isDisabled={form.formState.isSubmitting}
          />
          <Field
            className='mt-8 w-full'
            control={form.control}
            config={fieldConfig.password}
            error={form.formState.errors?.password}
            isDisabled={form.formState.isSubmitting}
          />
          <Button
            className='mt-5 w-full'
            onClick={() => form.clearErrors(['username', 'password'])}
            iconName={!form.formState.isSubmitting ? 'ArrowRight' : ''}
            iconPosition='right'
            isDisabled={form.formState.isSubmitting}
            isLoading={form.formState.isSubmitting}
            isSubmit
          >
            Submit
          </Button>
        </form>
      </Grid>
    </div>
  )
}

export default AccessForm
