"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBasket = useBasket;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _useBasket = require("../../hooks/useBasket");
var _useEinstein = require("./useEinstein");
var _contexts = require("../../contexts");
var _react = require("react");
var _useSfraApi = _interopRequireDefault(require("../../../../hooks/useSfraApi"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function useBasket(opts = {}) {
  const api = (0, _contexts.useCommerceAPI)();
  const self = (0, _useBasket.useBasket)(opts);
  const einstein = (0, _useEinstein.useEinstein)();
  const sfraApi = (0, _useSfraApi.default)();
  // @ts-ignore
  const {
    basket,
    setBasket: _setBasket
  } = (0, _react.useContext)(_contexts.BasketContext);
  const setBasket = basketData => {
    _setBasket(_objectSpread({}, basketData));
  };
  const customUseBasket = _objectSpread(_objectSpread({}, self), {}, {
    get wrongSelectedShipmentMethod() {
      var _basket$shipments, _basket$shipments$, _basket$shipments$$sh;
      return (basket === null || basket === void 0 ? void 0 : basket.c_containsVGC) !== (basket === null || basket === void 0 ? void 0 : (_basket$shipments = basket.shipments) === null || _basket$shipments === void 0 ? void 0 : (_basket$shipments$ = _basket$shipments[0]) === null || _basket$shipments$ === void 0 ? void 0 : (_basket$shipments$$sh = _basket$shipments$.shippingMethod) === null || _basket$shipments$$sh === void 0 ? void 0 : _basket$shipments$$sh.c_digital);
    },
    get itemTotal() {
      return ((basket === null || basket === void 0 ? void 0 : basket.productTotal) || 0) + ((basket === null || basket === void 0 ? void 0 : basket.c_giftCertificateTotal) || 0);
    },
    get itemSubTotal() {
      return ((basket === null || basket === void 0 ? void 0 : basket.productSubTotal) || 0) + ((basket === null || basket === void 0 ? void 0 : basket.c_giftCertificateTotal) || 0);
    },
    get openPaymentAmount() {
      let paidAmount = 0.0;
      ((basket === null || basket === void 0 ? void 0 : basket.paymentInstruments) || []).forEach(pi => {
        paidAmount += pi.amount;
      });
      const openAmount = Math.round(((basket === null || basket === void 0 ? void 0 : basket.orderTotal) || 0.0) * 100) / 100 - Math.round(paidAmount * 100) / 100;
      return openAmount;
    },
    get giftCertificate() {
      return ((basket === null || basket === void 0 ? void 0 : basket.paymentInstruments) || []).find(pi => pi.paymentMethodId === 'GIFT_CERTIFICATE') || null;
    },
    get productsQuantity() {
      const productItems = basket === null || basket === void 0 ? void 0 : basket.productItems;
      const giftCertificateItems = basket === null || basket === void 0 ? void 0 : basket.giftCertificateItems;
      return (productItems ? productItems.reduce((acc, actual) => acc + (actual === null || actual === void 0 ? void 0 : actual.quantity) || 0, 0) : 0) + (giftCertificateItems ? giftCertificateItems.length : 0);
    },
    get hasOfflineItemsInCart() {
      const items = ((basket === null || basket === void 0 ? void 0 : basket.productItems) || []).concat((basket === null || basket === void 0 ? void 0 : basket.giftCertificateItems) || []);
      return items.filter(item => {
        return !item.c_purchasable;
      }).length > 0;
    },
    addGiftCertificateItemToBasket(item, quantity = 1) {
      return (0, _asyncToGenerator2.default)(function* () {
        let response;
        for (let i = 0; i < quantity; i++) {
          // @ts-ignore
          response = yield api.shopperBaskets.addGiftCertificateItemToBasket({
            body: item,
            parameters: {
              basketId: basket.basketId
            }
          });
        }
        if (response.fault) {
          return response;
        } else {
          setBasket(response);
          einstein.sendAddToCart({
            productId: item.c_SKU,
            price: item.amount,
            quantity
          });
          return response;
        }
      })();
    },
    removeGiftCertificateItemFromBasket(itemId, basketId = basket.basketId) {
      return (0, _asyncToGenerator2.default)(function* () {
        const response = // @ts-ignore
        yield api.shopperBaskets.removeGiftCertificateItemFromBasket({
          parameters: {
            basketId,
            itemId: itemId
          }
        });
        if (response.fault) {
          throw new Error(response);
        } else {
          setBasket(response);
        }
      })();
    },
    checkGiftCertificateBalance(giftCertID) {
      return (0, _asyncToGenerator2.default)(function* () {
        const response = yield sfraApi.giftCertificates.checkBalance({
          query: {
            giftCertID
          }
        }).catch(err => {
          return {
            error: true,
            ErrorMsg: err.ErrorMsg
          };
        });
        return response;
      })();
    },
    redeemGiftCertificate(giftCertID) {
      return (0, _asyncToGenerator2.default)(function* () {
        const response = yield sfraApi.giftCertificates.redeem({
          query: {
            giftCertID
          }
        }).catch(err => {
          return {
            error: true,
            ErrorMsg: err.ErrorMsg
          };
        });
        if (!response.error) {
          const basketResponse = yield self.getOrCreateBasket();
          setBasket(basketResponse);
        }
        return response;
      })();
    },
    removeGiftCertificate(giftCertificateCode) {
      return (0, _asyncToGenerator2.default)(function* () {
        const response = yield sfraApi.giftCertificates.remove({
          query: {
            giftCertificateCode
          }
        }).catch(err => {
          return {
            error: true,
            ErrorMsg: err.ErrorMsg
          };
        });
        if (!response.error) {
          const basketResponse = yield self.getOrCreateBasket();
          setBasket(basketResponse);
        }
        return response;
      })();
    },
    setPaymentInstrument(paymentInstrument) {
      return (0, _asyncToGenerator2.default)(function* () {
        let existingPaymentInstrument = ((basket === null || basket === void 0 ? void 0 : basket.paymentInstruments) || []).find(pi => pi.paymentMethodId !== 'GIFT_CERTIFICATE');

        // @ts-ignore
        let response = yield api.shopperBaskets.addPaymentInstrumentToBasket({
          body: paymentInstrument,
          parameters: {
            basketId: basket.basketId
          }
        });
        if (existingPaymentInstrument) {
          // @ts-ignore
          response = yield api.shopperBaskets.removePaymentInstrumentFromBasket({
            parameters: {
              basketId: basket.basketId,
              paymentInstrumentId: existingPaymentInstrument === null || existingPaymentInstrument === void 0 ? void 0 : existingPaymentInstrument.paymentInstrumentId
            }
          });
        }
        setBasket(response);
      })();
    },
    removePaymentInstrument() {
      return (0, _asyncToGenerator2.default)(function* () {
        const paymentInstruments = (basket === null || basket === void 0 ? void 0 : basket.paymentInstruments) || [];
        if (paymentInstruments.length === 0) {
          return;
        }
        const promises = [];
        paymentInstruments.forEach(pi => {
          promises.push(
          // @ts-ignore
          api.shopperBaskets.removePaymentInstrumentFromBasket({
            parameters: {
              basketId: basket.basketId,
              paymentInstrumentId: pi.paymentInstrumentId
            }
          }).catch(() => {
            // Ignore missing id error
          }));
        });
        const responses = yield Promise.all(promises);
        setBasket(responses.pop());
      })();
    },
    /**
     * Remove an item from the basket.
     *
     * @param {string} itemId - The id of the basket item.
     */
    removeItemFromBasket(itemId, basketId = basket.basketId) {
      return (0, _asyncToGenerator2.default)(function* () {
        // @ts-ignore
        const response = yield api.shopperBaskets.removeItemFromBasket({
          parameters: {
            basketId: basketId,
            itemId: itemId
          }
        });
        if (response.fault) {
          throw new Error(response);
        } else {
          setBasket(response);
        }
      })();
    },
    mergeBasket() {
      return (0, _asyncToGenerator2.default)(function* () {
        var _response$productItem;
        // @ts-ignore
        const response = yield api.shopperBaskets.mergeBasket({
          headers: {
            'Content-Type': 'application/json' // This is not required since the request has no body but CommerceAPI throws a '419 - Unsupported Media Type' error if this header is removed.
          },
          parameters: {
            createDestinationBasket: true,
            // If the current shopper has an active basket, this parameter is ignored.
            productItemMergeMode: 'sum_quantities'
          }
        });
        if (response.fault) {
          throw new Error(response);
        }
        if (((_response$productItem = response.productItems) === null || _response$productItem === void 0 ? void 0 : _response$productItem.length) > 0 && response.c_containsVGC) {
          var _response$productItem2;
          (_response$productItem2 = response.productItems) === null || _response$productItem2 === void 0 ? void 0 : _response$productItem2.forEach(item => {
            if (item.c_giftCardIsVirtual) {
              customUseBasket.removeItemFromBasket(item.itemId, response.basketId);
            }
          });
        } else {
          // Merge response can not be extended on BE, so it doesn't include necessary data, instead a separate request is made to get the actual basket
          yield self.getOrCreateBasket();
        }
      })();
    },
    /**
     * Adds agentID to basket.
     *
     * @param {string} agentId - The agentID to be added to the basket.
     * @returns {Object} - API response containing data
     */
    addAgentIdToBasket(agentId) {
      return (0, _asyncToGenerator2.default)(function* () {
        // @ts-ignore
        const response = yield api.shopperBaskets.updateBasket({
          body: {
            c_agentID: agentId
          },
          parameters: {
            basketId: basket.basketId
          }
        });
        setBasket(response);
      })();
    },
    /**
     * Adds platform to basket.
     *
     * @param {string} platform - The platform to be added to the basket.Must be Shop or App
     * @returns {Object} - API response containing data
     */
    addPlatformToBasket(platform) {
      return (0, _asyncToGenerator2.default)(function* () {
        // @ts-ignore
        const response = yield api.shopperBaskets.updateBasket({
          body: {
            c_platform: platform
          },
          parameters: {
            basketId: basket.basketId
          }
        });
        setBasket(response);
      })();
    }
  });
  return customUseBasket;
}