// @ts-nocheck
import loadable from '@loadable/component'
import { ComponentMapping } from 'msp-integrations'
import { defaultComponentMapping } from 'msp-components'
import FullWidthIntroWrapper from './FullWidthIntroWrapper/FullWidthIntroWrapper'
import SpacerWrapper from './SpacerWrapper'
import JasminFooter from '@/components/organisms/JasminFooter/JasminFooter'
import NavItemComponent from './NavItem/NavItem'
import JasminCommerceBox from '@/components/molecules/JasminCommerceBox/JasminCommerceBox'

const Anchor = loadable(() => import('@/components/atoms/Anchor/Anchor'))
const Discovery = loadable(
  () => import('@/components/molecules/Discovery/Discovery')
)
const JasminContentWithBg = loadable(
  () => import('@/components/molecules/JasminContentWithBg/JasminContentWithBg')
)
const ProductTileComponent = loadable(
  () => import('@/components/molecules/ProductTile/ProductTile')
)
const ProductSetTeaser = loadable(
  () => import('@/components/cms/ProductSetTeaser')
)
const Header = loadable(() => import('./header'))
const ImageLinkWrapper = loadable(() => import('./ImageLinkWrapper'))
const MoodBoardWrapper = loadable(
  () => import('./MoodBoardWrapper/MoodBoardWrapper')
)
const CustomRecommendation = loadable(
  () =>
    import('@/components/organisms/CustomRecommendation/CustomRecommendation')
)
const EinsteinRecommendations = loadable(
  () =>
    import(
      '@/components/organisms/EinsteinRecommendations/EinsteinRecommendations'
    )
)
const ProductDetailsWrapper = loadable(() => import('./ProductDetailsWrapper'))
const CoffeeCategoryGrid = loadable(
  () => import('@/components/molecules/CoffeeCategoryGrid/CoffeeCategoryGrid')
)
const ServiceSearch = loadable(
  () => import('@/components/organisms/ServiceSearch/ServiceSearch')
)
const ServiceContact = loadable(
  () => import('@/components/molecules/ServiceContact/ServiceContact')
)
const VideoStandaloneComponent = loadable(
  () =>
    import(
      '@/components/organisms/VideoStandaloneComponent/VideoStandaloneComponent'
    )
)
const LandingHeaderWrapper = loadable(
  () => import('./LandingHeaderWrapper/LandingHeaderWrapper')
)
const Stage = loadable(() => import('@/components/organisms/Stage/Stage'))
const GalleryTextWrapper = loadable(() => import('./GalleryTextWrapper'))
const MachineCommerceStageWrapper = loadable(
  () => import('./MachineCommerceStageWrapper/MachineCommerceStageWrapper')
)
const JasminRecipe = loadable(
  () => import('@/components/organisms/JasminRecipe/JasminRecipe')
)

const JasminCartPromotionSlot = loadable(
  () => import('@/components/organisms/CartPromotionSlot/CartPromotionSlot')
)

const componentMapping: ComponentMapping = {
  ...defaultComponentMapping,
  ...{
    'pwa.anchor': Anchor,
    'pwa.cloudinaryVideo': VideoStandaloneComponent,
    'pwa.coffeeCategoryGrid': CoffeeCategoryGrid,
    'pwa.commerceBox': JasminCommerceBox,
    'pwa.contentWithBackground': JasminContentWithBg,
    'pwa.customRecommendation': CustomRecommendation,
    'pwa.discovery': Discovery,
    'pwa.einsteinRecommendations': EinsteinRecommendations,
    'pwa.fullWidthIntro': FullWidthIntroWrapper,
    'pwa.galleryText': GalleryTextWrapper,
    'pwa.Header': Header,
    'pwa.image': ImageLinkWrapper,
    'pwa.landingHeader': LandingHeaderWrapper,
    'pwa.moodBoard': MoodBoardWrapper,
    'pwa.navItem': NavItemComponent,
    'pwa.productDetails': ProductDetailsWrapper,
    'pwa.productSetTeaser': ProductSetTeaser,
    'pwa.productTile': ProductTileComponent,
    'pwa.serviceContact': ServiceContact,
    'pwa.ServiceSearch': ServiceSearch,
    'pwa.spacer': SpacerWrapper,
    'pwa.stage': Stage,
    'pwa.machineCommerceStage': MachineCommerceStageWrapper,
    'pwa.Footer': JasminFooter,
    'pwa.recipe': JasminRecipe,
    'pwa.cartPromotionSlot': JasminCartPromotionSlot
  }
}

export default componentMapping
