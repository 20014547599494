import React from 'react'
import { IconsProps } from 'msp-integrations'

const UserAccountIcon = (props: IconsProps) => {
  return (
    <svg
      width='24'
      height='23'
      viewBox='0 0 24 23'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d="M13.127 13.788c1.019-.712 2.23-1.907 2.246-1.926 1.443-1.44 2.452-3.692 2.452-5.474A5.6 5.6 0 0 0 16.16 2.39C15.068 1.307 13.623.675 12.056.719 8.879.74 6.287 3.308 6.284 6.447c-.002 1.904.772 3.651 2.368 5.345.618.655 1.521 1.478 2.282 2.001-4.666.459-8.711 3.736-10.051 8.29l.776.23c1.349-4.577 5.626-7.773 10.4-7.773 4.773 0 9.05 3.196 10.399 7.773l.777-.23c-1.354-4.568-5.421-7.855-10.108-8.295ZM9.24 11.239C7.795 9.702 7.09 8.138 7.093 6.45c.006-2.696 2.234-4.902 4.97-4.916h.028c1.327 0 2.57.51 3.5 1.435a4.792 4.792 0 0 1 1.427 3.422c0 1.562-.93 3.622-2.211 4.9-1.276 1.274-2.5 2.16-2.748 2.21-.278-.039-1.557-.926-2.819-2.26Z"/>
    </svg>
  )
}

export default UserAccountIcon
