"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SFRAApiClient = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _utils = require("./utils");
var _auth = require("./auth");
var _suggestions = require("./suggestions");
var _locales = require("./locales");
var _dhlexpress = require("./dhlexpress");
var _order = require("./order");
var _machines = require("./machines");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Class to request any available SFRA endpoint
 */
class SFRAApiClient {
  constructor(config) {
    this._config = config;
    const fetch = (0, _utils.createSFRAFetch)(config);
    const fetchWithSFRAAlias = (0, _utils.createSFRAFetch)(_objectSpread(_objectSpread({}, config), {}, {
      alias: true
    }));
    this.OcapiSearch = new _suggestions.Suggestions(config, fetch);
    this.Auth = new _auth.Auth(config, fetch);
    this.Site = new _locales.Locales(config, fetch);
    this.DHLExpress = new _dhlexpress.DHLExpress(config, fetch);
    this.Order = new _order.Order(config, fetch);
    this.Machines = new _machines.Machines(config, fetchWithSFRAAlias);
  }

  /**
   * Returns the api client configuration
   * @returns {ClientConfig}
   */
  getConfig() {
    return this._config;
  }
}
exports.SFRAApiClient = SFRAApiClient;