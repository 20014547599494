"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformRegionToMap = exports.mergeRegions = exports.isExternalUrl = exports.getRegionByID = exports.formatPageResponse = void 0;
const mergeRegions = (pageRegions, globalPageRegions) => {
  const globalPageRegionsMap = new Map();
  globalPageRegions.forEach(region => {
    globalPageRegionsMap.set(region.id, region);
  });
  const mergedRegions = [];
  pageRegions.forEach(region => {
    if (globalPageRegionsMap.has(region.id) && (!region.components || region.components.lenght == 0)) {
      mergedRegions.push(globalPageRegionsMap.get(region.id));
    } else {
      mergedRegions.push(region);
    }
  });
  return mergedRegions;
};
exports.mergeRegions = mergeRegions;
const getRegionByID = (regions, id) => {
  if (!regions || !id) return null;
  return regions.find(({
    regionId
  }) => regionId === id);
};
exports.getRegionByID = getRegionByID;
const transformRegionToMap = regions => {
  const regionMap = new Map();
  if (!regions) return new Map();
  regions.forEach(region => {
    regionMap.set(region.id, region);
  });
  return regionMap;
};
exports.transformRegionToMap = transformRegionToMap;
const formatPageResponse = rawPage => {
  if (rawPage) {
    return {
      id: rawPage.data.content.id,
      meta: {
        title: rawPage.data.title,
        description: rawPage.data.description,
        keywords: rawPage.data.keywords,
        canonicals: rawPage.data.canonicals
      },
      found: true,
      pageFetchedById: rawPage.data.pageFetchedById,
      url: rawPage.data.url,
      content: rawPage.data.content
    };
  }
  return undefined;
};
exports.formatPageResponse = formatPageResponse;
const isExternalUrl = url => /(http(s?)):\/\//i.test(url);
exports.isExternalUrl = isExternalUrl;