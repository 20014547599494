import loadable from '@loadable/component'
import MenuIcon from '@/assets/svg/shared-icons/MenuIcon'
import CloseIcon from '@/assets/svg/shared-icons/CloseIcon'
import FacebookIcon from '@/assets/svg/shared-icons/FacebookIcon'
import PinterestIcon from '@/assets/svg/shared-icons/PinterestIcon'
import InstagramIcon from '@/assets/svg/shared-icons/InstagramIcon'
import YoutubeIcon from '@/assets/svg/shared-icons/Youtube'

export const AsyncSharedIcon = (icon: string) =>
  loadable(() => import(`@/assets/svg/shared-icons/${icon}`))

// Mapping
const SVGMapping = {
  Close: CloseIcon,
  Facebook: FacebookIcon,
  Instagram: InstagramIcon,
  Menu: MenuIcon,
  Pinterest: PinterestIcon,
  Youtube: YoutubeIcon,
  AppleAppStore: AsyncSharedIcon('AppleAppStoreIcon'),
  ArrowDown: AsyncSharedIcon('ArrowDownIcon'),
  ArrowDownFilled: AsyncSharedIcon('ArrowDownFilledIcon'),
  ArrowLeft: AsyncSharedIcon('ArrowLeftIcon'),
  ArrowRight: AsyncSharedIcon('ArrowRightIcon'),
  ArrowRightStick: AsyncSharedIcon('ArrowRightStickIcon'),
  ArrowUp: AsyncSharedIcon('ArrowUpIcon'),
  ArrowUpFilled: AsyncSharedIcon('ArrowUpFilledIcon'),
  Attention: AsyncSharedIcon('AttentionIcon'),
  Bio: AsyncSharedIcon('BioIcon'),
  Bluetooth: AsyncSharedIcon('BluetoothIcon'),
  Building: AsyncSharedIcon('BuildingIcon'),
  Calendar: AsyncSharedIcon('CalendarIcon'),
  Chat: AsyncSharedIcon('ChatIcon'),
  Check: AsyncSharedIcon('CheckIcon'),
  Confirmation: AsyncSharedIcon('ConfirmationIcon'),
  Cup: AsyncSharedIcon('CupIcon'),
  Customizability: AsyncSharedIcon('CustomizabilityIcon'),
  Debit: AsyncSharedIcon('DebitIcon'),
  DebitCard: AsyncSharedIcon('DebitCardIcon'),
  Decalcification: AsyncSharedIcon('DecalcificationIcon'),
  Delete: AsyncSharedIcon('DeleteIcon'),
  DHL: AsyncSharedIcon('DHLIcon'),
  DHLExpress: AsyncSharedIcon('DHLExpressIcon'),
  Download: AsyncSharedIcon('DownloadIcon'),
  Edit: AsyncSharedIcon('EditIcon'),
  EditOrder: AsyncSharedIcon('EditOrderIcon'),
  EyeOff: AsyncSharedIcon('EyeOff'),
  EyeOn: AsyncSharedIcon('EyeOn'),
  FacebookMessenger: AsyncSharedIcon('FacebookMessengerIcon'),
  FairTrade: AsyncSharedIcon('FairTradeIcon'),
  File: AsyncSharedIcon('FileIcon'),
  GoogleAppStore: AsyncSharedIcon('GoogleAppStoreIcon'),
  Home: AsyncSharedIcon('HomeIcon'),
  House: AsyncSharedIcon('HouseIcon'),
  Information: AsyncSharedIcon('InformationIcon'),
  Invoice: AsyncSharedIcon('InvoiceIcon'),
  InvoiceWhite: AsyncSharedIcon('InvoiceWhiteIcon'),
  Link: AsyncSharedIcon('LinkIcon'),
  Logout: AsyncSharedIcon('LogoutIcon'),
  MasterCard: AsyncSharedIcon('MasterCardIcon'),
  MoreServices: AsyncSharedIcon('MoreServicesIcon'),
  MoreServicesText: AsyncSharedIcon('MoreServicesTextIcon'),
  NoWater: AsyncSharedIcon('NoWaterIcon'),
  OrderConfirmation: AsyncSharedIcon('OrderConfirmationIcon'),
  OrderInvoice: AsyncSharedIcon('OrderInvoiceIcon'),
  Pause: AsyncSharedIcon('PauseIcon'),
  PayPal: AsyncSharedIcon('PayPalIcon'),
  Play: AsyncSharedIcon('PlayIcon'),
  Plus: AsyncSharedIcon('PlusIcon'),
  PowerButton: AsyncSharedIcon('PowerButtonIcon'),
  Receipt: AsyncSharedIcon('ReceiptIcon'),
  Repair: AsyncSharedIcon('RepairIcon'),
  RepairText: AsyncSharedIcon('RepairTextIcon'),
  ReturnLabel: AsyncSharedIcon('ReturnLabelIcon'),
  Search: AsyncSharedIcon('SearchIcon'),
  Security: AsyncSharedIcon('SecurityIcon'),
  Send: AsyncSharedIcon('SendIcon'),
  Send180: AsyncSharedIcon('Send180Icon'),
  Send4: AsyncSharedIcon('Send4Icon'),
  SendWithDot: AsyncSharedIcon('SendWithDotIcon'),
  SoundEqualizer: AsyncSharedIcon('SoundEqualizerIcon'),
  SoundMute: AsyncSharedIcon('SoundMuteIcon'),
  Square: AsyncSharedIcon('SquareIcon'),
  SquareFilled: AsyncSharedIcon('SquareFilledIcon'),
  Steering: AsyncSharedIcon('SteeringIcon'),
  SteeringText: AsyncSharedIcon('SteeringTextIcon'),
  Telephone: AsyncSharedIcon('TelephoneIcon'),
  Twitter: AsyncSharedIcon('TwitterIcon'),
  Update: AsyncSharedIcon('UpdateIcon'),
  VideoExpand: AsyncSharedIcon('VideoExpandIcon'),
  VisaCard: AsyncSharedIcon('VisaCardIcon'),
  VolumeControl: AsyncSharedIcon('VolumeControlIcon'),
  Web: AsyncSharedIcon('WebIcon'),
  Whatsapp: AsyncSharedIcon('WhatsAppIcon'),
  Copy: AsyncSharedIcon('CopyIcon'),
  Refresh: AsyncSharedIcon('Refresh')
}

export default SVGMapping
