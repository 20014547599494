"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _Dynamic = require("./pages/Dynamic");
Object.keys(_Dynamic).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Dynamic[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Dynamic[key];
    }
  });
});
var _Region = require("./pages/Region");
Object.keys(_Region).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Region[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Region[key];
    }
  });
});
var _Regions = require("./pages/Regions");
Object.keys(_Regions).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _Regions[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _Regions[key];
    }
  });
});