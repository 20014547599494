"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useConfig = void 0;
var _react = _interopRequireDefault(require("react"));
var _ConfigProvider = require("../providers/ConfigProvider");
const useConfig = () => {
  const context = _react.default.useContext(_ConfigProvider.ConfigContext);
  if (context === null) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  const {
    config
  } = context;
  return config;
};
exports.useConfig = useConfig;