"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ScapiSitesApi = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _utils = require("./utils");
class ScapiSitesApi {
  constructor(config) {
    this.fetch = (0, _utils.createScapiFetch)(config, 'sites');
  }
  current(...args) {
    var _this = this;
    return (0, _asyncToGenerator2.default)(function* () {
      const url = `current`;
      return _this.fetch(url, 'GET', args);
    })();
  }
}
exports.ScapiSitesApi = ScapiSitesApi;