"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSfraApiPage = useSfraApiPage;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _utils = require("../../../utils");
var _useQuery = require("../../useQuery");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function useSfraApiPage(args, options) {
  if (!args.query.pageIdOrSlug) {
    throw new Error('pageIdOrSlug is required for useSfraApiPage.');
  }
  const globalPageRequestArgs = {
    query: {
      pageIdOrSlug: 'global'
    }
  };
  const {
    data: globalPageResult
  } = (0, _useQuery.useQuery)(['sfraApiPage', globalPageRequestArgs], (_, {
    pages: service
  }) => service.getPage(globalPageRequestArgs), options);
  return (0, _useQuery.useQuery)(['sfraApiPage', args], (_, {
    pages: service
  }) => service.getPage(args).catch(() => null), _objectSpread(_objectSpread({}, options), {}, {
    select: data => {
      let page = (0, _utils.formatPageResponse)(data);
      if (globalPageResult) {
        const globalPage = (0, _utils.formatPageResponse)(globalPageResult);
        if (!page) {
          page = _objectSpread({}, globalPage);
          page.id = args.query.pageIdOrSlug;
          page.content.id = args.query.pageIdOrSlug;
          page.found = false;
        }
        page.content.regions = (0, _utils.mergeRegions)(page.content.regions, globalPage.content.regions);
      }
      return page;
    }
  }));
}