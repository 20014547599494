"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCookie = void 0;
var _react = require("react");
var _CookieProvider = require("../providers/CookieProvider");
const useCookie = () => {
  return (0, _react.useContext)(_CookieProvider.CookieContext);
};
exports.useCookie = useCookie;