"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Auth = void 0;
/**
 * Class to request custom SFRA auth endpoint
 */
class Auth {
  /**
   * Create an instance of the API with the given config.
   * @param {ClientConfig} config - The config used to instantiate SDK apis.
   */
  constructor(config, fetch) {
    this._config = config;
    this._fetch = fetch;
  }
  setNewPassword(args) {
    return this._fetch(`Account-SaveNewPassword`, 'post', args, 'SetNewPassword');
  }
  submitGuestRegistration(args) {
    return this._fetch('Account-SubmitGuestRegistration', 'post', args);
  }
}
exports.Auth = Auth;