import React from 'react'
import { useIntl } from 'react-intl'
import {
  ServiceCustomLink,
  SocialMediaElements,
  ShowOnDesktop,
  ShowOnMobile,
  Icons,
  Image
} from 'msp-components'
import { getAssetUrl } from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'

const DEFAULT_LANGUAGE = 'de'
const SOCIAL_MEDIA_CONTENT = [
  {
    iconName: 'Facebook',
    link: 'https://www.facebook.com/avourytea'
  },
  {
    iconName: 'Pinterest',
    link: 'https://www.pinterest.de/avourythetea/'
  },
  {
    iconName: 'Instagram',
    link: 'https://www.instagram.com/avoury.de/'
  },
  {
    iconName: 'Youtube',
    link: 'https://www.youtube.com/@avoury.thetea.8781/featured'
  }
]

export const FirstContentSlot = ({
  children
}: {
  children: React.ReactNode
}) => (
  <ul className='p text-20 order-3 col-span-2 flex flex-col md:col-span-1 lg:order-none'>
    {children}
  </ul>
)

export const SecondContentSlot = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { formatMessage } = useIntl()

  return (
    <ShowOnDesktop className='order-1 mb-0 pl-0 md:mb-8 lg:order-none lg:mb-0 lg:pl-10'>
      {children}
      <ServiceCustomLink
        textClassname='font-Roboto font-normal text-16 leading-20 underline'
        isExternalLink={false}
        uppercase={false}
        url={formatMessage({
          defaultMessage: '/service#contact',
          id: 'msp.footer.contactLink'
        })}
      >
        {formatMessage({
          defaultMessage: 'Contact Form',
          id: 'msp.footer.contact'
        })}
      </ServiceCustomLink>
      <ServiceCustomLink
        textClassname='font-Roboto font-normal text-16 leading-20 underline lowercase'
        url='tel:00800 7000 6000'
      >
        00800 7000 6000
      </ServiceCustomLink>
      <ServiceCustomLink
        textClassname='font-Roboto text-16 leading-20 underline capitalize'
        url='/press'
        isExternalLink={false}
      >
        {formatMessage({
          defaultMessage: 'Press',
          id: 'msp.footer.presslink'
        })}
      </ServiceCustomLink>
      <SocialMediaElements socialMediaItems={SOCIAL_MEDIA_CONTENT} />
    </ShowOnDesktop>
  )
}

export const ThirdContentSlot = ({
  children
}: {
  children: React.ReactNode
}) => (
  <ul className='order-2 mb-0 pl-0 md:mb-8 lg:order-none lg:mb-0 lg:pl-10'>
    {children}
  </ul>
)

export const FourthContentSlot = ({
  children
}: {
  children: React.ReactNode
}) => {
  const { formatMessage, locale } = useIntl()
  const currentLanguage = locale?.split('-')[0] || DEFAULT_LANGUAGE
  const priceInfoText = formatMessage({
    defaultMessage: '*alle Preise inkl. MwSt. zzgl. Versandkosten',
    id: 'msp.footer.price_info'
  })

  const playStoreLink = `https://play.google.com/store/apps/details?id=com.msp.avoury&hl=${currentLanguage}&gl=US`
  const pciLink = `https://my-pci.usd.de/compliance/0980D68A-36FA-4DFF-A7E6-51471BBC8AE0/details_${currentLanguage}.html`
  let appStoreLink = 'https://apps.apple.com/de/app/avoury-the-tea/id1487794999'

  if (currentLanguage !== DEFAULT_LANGUAGE) {
    appStoreLink = `${appStoreLink}?l=${currentLanguage}`
  }

  return (
    <ShowOnDesktop className='text-20 order-4 col-span-2 md:col-span-1 lg:order-none'>
      {children}
      <div className='flex w-full flex-row pt-3'>
        <div className='flex flex-col gap-2 pr-3'>
          <ServiceCustomLink
            url={appStoreLink}
            icon={
              <Icons
                iconName='AppleAppStore'
                asFile
                width='94px'
                height='32px'
              />
            }
            iconWrapperClassname='fill-white'
          />
        </div>
        <div className='flex flex-col gap-2'>
          <ServiceCustomLink
            url={playStoreLink}
            icon={
              <Icons
                iconName='GoogleAppStore'
                asFile
                width='108px'
                height='32px'
              />
            }
            iconWrapperClassname='fill-white'
          />
        </div>
      </div>
      <div className='text-14 tracking-0.2 text-machine1-50 mt-4'>
        {priceInfoText}
      </div>
      <div className='mt-10 flex gap-6'>
        <Icons
          iconName='LogoPremiumWebshopIcon'
          asFile
          height='60px'
          width='100px'
        />
        <a
          href={pciLink}
          title='PCI DSS compliant | usd AG'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={getAssetUrl('static/img/seal-PCI-DSS.png')}
            alt='PCI DSS compliant | usd AG'
            loading='lazy'
          />
        </a>
      </div>
    </ShowOnDesktop>
  )
}

export const PreFooterMobileSlot = () => {
  const { formatMessage } = useIntl()
  return (
    <ShowOnMobile>
      <div className='mt-8 flex justify-center'>
        <SocialMediaElements socialMediaItems={SOCIAL_MEDIA_CONTENT} />
      </div>
      <div className='mt-10 flex flex-col items-center justify-center'>
        <div className='border-machine1-75 font-Roboto leading-32 flex font-normal'>
          <ServiceCustomLink
            textClassname='font-Roboto font-normal text-16 leading-20 underline'
            isExternalLink={false}
            uppercase={false}
            url={formatMessage({
              defaultMessage: '/service#contact',
              id: 'msp.footer.contactLink'
            })}
          >
            {formatMessage({
              defaultMessage: 'Contact Form',
              id: 'msp.footer.contact'
            })}
          </ServiceCustomLink>
        </div>
        <div className='border-machine1-75 font-Roboto leading-32 flex font-normal'>
          <ServiceCustomLink
            textClassname='font-Roboto font-normal text-16 leading-20 underline lowercase'
            url='tel:00800 7000 6000'
          >
            00800 7000 6000
          </ServiceCustomLink>
        </div>
      </div>
    </ShowOnMobile>
  )
}

export const PostFooterMobileSlot = () => {
  const { formatMessage, locale } = useIntl()
  const priceInfoText = formatMessage({
    defaultMessage: '*alle Preise inkl. MwSt. zzgl. Versandkosten',
    id: 'msp.footer.price_info'
  })
  const currentLanguage = locale?.split('-')[0] || DEFAULT_LANGUAGE
  const pciLink = `https://my-pci.usd.de/compliance/0980D68A-36FA-4DFF-A7E6-51471BBC8AE0/details_${currentLanguage}.html`

  return (
    <ShowOnMobile>
      <div className='font-Roboo text-14 text-gray92 tracking-0 flex flex-col items-center md:items-start'>
        <div>{priceInfoText}</div>
        <div className='flex pb-4 pt-6'>
          <div className='flex-auto pr-2.5'>
            <ServiceCustomLink
              url='#'
              icon={
                <Icons
                  iconName='AppleAppStore'
                  asFile
                  width='94px'
                  height='32px'
                />
              }
              iconWrapperClassname='fill-white'
            />
          </div>
          <div className='flex-auto'>
            <ServiceCustomLink
              url='#'
              icon={
                <Icons
                  iconName='GoogleAppStore'
                  asFile
                  width='108px'
                  height='32px'
                />
              }
              iconWrapperClassname='fill-white'
            />
          </div>
        </div>
        <div className='mb-10 flex gap-6'>
          <Icons
            iconName='LogoPremiumWebshopIcon'
            asFile
            height='60px'
            width='100px'
          />
          <a
            href={pciLink}
            title='PCI DSS compliant | usd AG'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={getAssetUrl('static/img/seal-PCI-DSS.png')}
              alt='PCI DSS compliant | usd AG'
              loading='lazy'
            />
          </a>
        </div>
      </div>
    </ShowOnMobile>
  )
}
