"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePageDesignerEditMode = void 0;
var _reactRouterDom = require("react-router-dom");
var _queryString = _interopRequireDefault(require("query-string"));
const usePageDesignerEditMode = () => {
  const location = (0, _reactRouterDom.useLocation)();
  const params = _queryString.default.parse(location.search);
  const {
    pageDesignerEditMode: rawPageDesignerEditMode
  } = params;
  return rawPageDesignerEditMode && rawPageDesignerEditMode === 'true';
};
exports.usePageDesignerEditMode = usePageDesignerEditMode;