"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createSfraFetch = require("../utils/createSfraFetch");
var _Pages = _interopRequireDefault(require("./sfra-api/Pages"));
var _GiftCertificates = _interopRequireDefault(require("./sfra-api/GiftCertificates"));
class SfraApiClient {
  constructor(config) {
    this._config = config;
    const fetch = (0, _createSfraFetch.createSfraFetch)(config);
    this.Pages = new _Pages.default(fetch, config.componentMapping);
    this.GiftCertificates = new _GiftCertificates.default(fetch);
  }
  getConfig() {
    return this._config;
  }
}
var _default = exports.default = SfraApiClient;