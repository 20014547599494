"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMutation = void 0;
var _reactQuery = require("@tanstack/react-query");
var _useClient = _interopRequireDefault(require("./useClient"));
const useMutation = (fn, mutationOptions) => {
  return (0, _reactQuery.useMutation)((0, _useClient.default)(fn), mutationOptions);
};
exports.useMutation = useMutation;