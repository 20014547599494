"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Suggestions = void 0;
/**
 * Class to request custom SFRA suggestions endpoint
 */
class Suggestions {
  /**
   * Create an instance of the API with the given config.
   * @param {ClientConfig} config - The config used to instantiate SDK apis.
   */
  constructor(config, fetch) {
    this._config = config;
    this._fetch = fetch;
  }
  searchSuggestions(args) {
    return this._fetch('OcapiSearch-Suggestions', 'get', args, 'SearchSuggestions');
  }
  searchContent(args) {
    return this._fetch('OcapiSearch-Content', 'get', args, 'SearchContent');
  }
}
exports.Suggestions = Suggestions;