"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWindowSize = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("react");
var _hooks = require("@salesforce/pwa-kit-react-sdk/ssr/universal/hooks");
var _utils = require("../utils/utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } // @ts-ignore
// Reference: https://tailwindcss.com/docs/responsive-design
const BREAKPOINTS_TAILWIND = {
  xs: 420,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
};
const getDevice = () => {
  let isMobile = false;
  let isTablet = false;
  let isDesktop = false;
  if (window.matchMedia(`(max-width: ${BREAKPOINTS_TAILWIND.sm}px)`).matches) {
    isMobile = true;
  } else if (window.matchMedia(`(max-width: ${BREAKPOINTS_TAILWIND.lg}px)`).matches) {
    isTablet = true;
  } else {
    isDesktop = true;
  }
  return {
    isMobile,
    isTablet,
    isDesktop
  };
};
const useWindowSize = () => {
  let initialWidth;
  let initialHeight = 768;
  if (_utils.isServer) {
    const {
      req
    } = (0, _hooks.useServerContext)();
    if (req) {
      const useragent = req.useragent;
      if (useragent.isDesktop) {
        initialWidth = 1024;
      } else if (useragent.isMobile) {
        initialWidth = 639;
      } else {
        initialWidth = 1023;
      }
    }
  } else {
    const device = getDevice();
    if (device.isDesktop) {
      initialWidth = 1024;
    } else if (device.isMobile) {
      initialWidth = 639;
    } else {
      initialWidth = 1023;
    }
  }
  const [windowSize, setWindowSize] = (0, _react.useState)({
    width: initialWidth,
    height: initialHeight,
    breakpoints: BREAKPOINTS_TAILWIND
  });
  (0, _react.useEffect)(() => {
    function handleResize() {
      setWindowSize(wS => _objectSpread(_objectSpread({}, wS), {}, {
        width: document.body.clientWidth || window.innerWidth,
        height: document.body.clientHeight || window.innerHeight
      }));
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};
exports.useWindowSize = useWindowSize;