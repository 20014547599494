import React from 'react'
import { IconsProps } from 'msp-integrations'

const BasketIcon = (props: IconsProps) => {
  return (
    <svg
      width='30'
      height='22'
      viewBox='0 0 30 22'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d="M17.621.366 14.534 7.96l.75.305L18.37.67l-.749-.305Z" /><path d="M18.522 7.321v.394a3.42 3.42 0 0 1-3.414 3.398 3.416 3.416 0 0 1-3.404-3.274v-.52H.394l6.005 14.533h17.517l5.911-14.517-11.305-.014Zm4.852 13.725H6.944L1.604 8.13h9.318c.221 2.125 2.001 3.792 4.183 3.792s3.962-1.667 4.183-3.792l9.34.01-5.254 12.906Z" />
    </svg>
  )
}

export default BasketIcon
