"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useChat = void 0;
var _react = require("react");
var _contexts = require("../contexts");
/**
 * Custom React hook to get the chat initialization status and to change it to a different value
 * @returns {{isInitialized: boolean, setIsInitialized: function}[]}
 */
const useChat = () => (0, _react.useContext)(_contexts.ChatContext);
exports.useChat = useChat;