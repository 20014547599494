import React from 'react'
import { Spacer } from '@/components/atoms/Spacer/Spacer'
import { transformRegionToMap } from 'msp-components'

const SpacerWrapper = ({ data, regions }: { data: any; regions: any }) => {
  const content = transformRegionToMap(regions)?.get('content')
  return <Spacer content={content} {...data} />
}

export default SpacerWrapper
