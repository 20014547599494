"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
class SfraApiGiftCertificates {
  constructor(fetch) {
    this._fetch = fetch;
  }
  checkBalance(args) {
    var _this = this;
    return (0, _asyncToGenerator2.default)(function* () {
      return _this._fetch('GiftCert-CheckBalance', 'POST', args, 'GetGiftCertificateBalance');
    })();
  }
  redeem(args) {
    var _this2 = this;
    return (0, _asyncToGenerator2.default)(function* () {
      return _this2._fetch('GiftCert-Redeem', 'POST', args, 'PostGiftCertificatRedeem');
    })();
  }
  remove(args) {
    var _this3 = this;
    return (0, _asyncToGenerator2.default)(function* () {
      return _this3._fetch('GiftCert-RemoveGiftCertificate', 'POST', args, 'PostGiftCertificateRemove');
    })();
  }
}
var _default = exports.default = SfraApiGiftCertificates;