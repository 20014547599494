"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PRODUCT_STATUS = exports.Order = exports.ORDER_STATUS = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _utils = require("@salesforce/pwa-kit-react-sdk/ssr/universal/utils");
// @ts-ignore

const ORDER_STATUS = exports.ORDER_STATUS = {
  PROCESSING: 'PROCESSING',
  READY_FOR_SHIPPING: 'READY_FOR_SHIPPING',
  SHIPPED: 'SHIPPED',
  DELIVERED: 'DELIVERED',
  RETURNED: 'RETURNED',
  PARTIALLY_RETURNED: 'PARTIALLY_RETURNED',
  CANCELLED: 'CANCELLED'
};
const PRODUCT_STATUS = exports.PRODUCT_STATUS = {
  SHIPPED: 'statusShipped',
  NOT_SHIPPED: 'statusNotShipped',
  DELIVERED: 'statusDelivered',
  RETURNED: 'statusReturned',
  PARTIALLY_RETURNED: 'statusPartiallyReturned',
  CANCELLED: 'statusCancelled'
};
class Order {
  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  orderPriceAdjustments = [];
  paymentInstruments = [];
  // @ts-ignore

  productItems = [];
  giftCertificateItems = [];
  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  shipments = [];
  shippingItems = [];
  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  c_invoice = [];
  c_orderstatus = [];
  // @ts-ignore

  c_return = [];
  c_returnLabel = [];
  c_shipping = [];
  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  // @ts-ignore

  totalProducts = 0;
  itemTotal = 0.0;
  itemSubTotal = 0.0;
  constructor(order) {
    (0, _extends2.default)(this, order);
    this.transformData(order);
  }
  transformData(order) {
    if (this.hasReturnLabel()) {
      try {
        this.c_returnLabel = order.c_returnLabel.map(item => JSON.parse(item));
      } catch {
        this.c_returnLabel = [];
      }
    }
    if (this.hasInvoices()) {
      try {
        this.c_invoice = order.c_invoice.map(item => JSON.parse(item));
      } catch {
        this.c_invoice = [];
      }
    }
    this.totalProducts = (this.productItems ? this.productItems.reduce((acc, actual) => acc + (actual === null || actual === void 0 ? void 0 : actual.quantity) || 0, 0) : 0) + (this.giftCertificateItems ? this.giftCertificateItems.length : 0);
    this.itemTotal = (this.productTotal || 0) + (this.c_giftCertificateTotal || 0);
    this.itemSubTotal = (this.productSubTotal || 0) + (this.c_giftCertificateTotal || 0);
  }
  getProductFallbackImage() {
    return (0, _utils.getAssetUrl)('static/img/fallback-product-image.png');
  }
  getShipment() {
    // @ts-ignore
    if (!this.hasShipments()) return null;
    const [shipment] = this.shipments;
    return shipment;
  }
  getPaymentMethod() {
    // @ts-ignore
    if (!this.paymentInstruments) return null;
    const [paymentInstrument] = this.paymentInstruments;
    return paymentInstrument;
  }
  getTotalOrderPriceAdjustments() {
    // @ts-ignore
    if (!this.hasPriceAdjustments()) return null;
    const priceAdjustments = this.orderPriceAdjustments.reduce((acc, orderPriceAdjustment) => {
      return acc + orderPriceAdjustment.price;
    }, 0);
    return priceAdjustments;
  }
  getProductImages() {
    const productImages = this.productItems.map(productItem => {
      const image = {
        productId: productItem.productId,
        src: productItem.c_image || this.getProductFallbackImage(),
        alt: productItem.productName
      };
      return image;
    });
    (this.giftCertificateItems || []).forEach(giftCertificateItem => {
      productImages.push({
        productId: giftCertificateItem.c_originalSKU,
        src: giftCertificateItem.c_image || this.getProductFallbackImage(),
        alt: giftCertificateItem.c_productName
      });
    });
    return productImages;
  }
  isPartiallyReturned() {
    return this.c_overallStatus === ORDER_STATUS.PARTIALLY_RETURNED;
  }
  isDelivered() {
    return this.c_overallStatus === ORDER_STATUS.DELIVERED;
  }
  isReturned() {
    return this.c_overallStatus === ORDER_STATUS.RETURNED;
  }
  isShipped() {
    return this.c_overallStatus === ORDER_STATUS.SHIPPED;
  }
  isConfirmed() {
    return this.c_overallStatus === ORDER_STATUS.PROCESSING;
  }
  isCancelled() {
    return this.c_overallStatus === ORDER_STATUS.CANCELLED;
  }
  isCancellable() {
    return (this.status === 'new' || this.status === 'open') && this.exportStatus === 'ready';
  }
  isReturnable() {
    return !this.isCancellable() && this.hasReturnLabel() && !this.isReturned();
  }
  hasInvoices() {
    return this.c_invoice && this.c_invoice.length > 0;
  }
  hasReturnLabel() {
    return this.c_returnLabel && this.c_returnLabel.length > 0;
  }
  hasOrderStatuses() {
    return this.c_orderstatus && this.c_orderstatus.length > 0;
  }
  hasReturnStatuses() {
    return this.c_return && this.c_return.length > 0;
  }
  hasTracking() {
    return this.c_shipping && this.c_shipping.length > 0;
  }
  hasShipments() {
    return this.shipments && this.shipments.length > 0;
  }
  hasPriceAdjustments() {
    return this.orderPriceAdjustments && this.orderPriceAdjustments.length > 0;
  }
}
exports.Order = Order;