"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _IRequestArgs = require("./IRequestArgs");
Object.keys(_IRequestArgs).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _IRequestArgs[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _IRequestArgs[key];
    }
  });
});
var _IResponse = require("./IResponse");
Object.keys(_IResponse).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _IResponse[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _IResponse[key];
    }
  });
});