import React from 'react'
import { IconsProps } from 'msp-integrations'

const FacebookIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 141.73 141.73'
    >
      <path d="M70.87 30.74c-22.3 0-40.37 18.08-40.37 40.37 0 20.15 14.76 36.85 34.07 39.88V82.78H54.31V71.11h10.25v-8.89c0-10.12 6.03-15.71 15.25-15.71 2.21 0 4.47.2 6.17.39.43.05.82.1 1.17.14 1.04.14 1.7.25 1.7.25v9.94h-5.09c-1.94 0-3.35.47-4.37 1.23-1.61 1.2-2.21 3.12-2.21 5.08v7.57h11.2l-1.79 11.67h-9.41v28.21c19.3-3.03 34.06-19.73 34.06-39.88 0-22.3-18.08-40.37-40.37-40.37z"/>
    </svg>
  )
}

export default FacebookIcon
