"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Machines = void 0;
/**
 * Class to request custom SFRA Machine endpoint
 */
class Machines {
  /**
   * Create an instance of the API with the given config.
   * @param {ClientConfig} config - The config used to instantiate SDK apis.
   */
  constructor(config, fetch) {
    this._config = config;
    this._fetch = fetch;
  }
  getMachines(args) {
    return this._fetch('api/machines', 'get', args);
  }
  getRegisterableMachines(args) {
    return this._fetch('api/machines/registerable-machines', 'get', args);
  }
  addMachine(args) {
    return this._fetch('api/machines/create', 'post', args);
  }
  removeMachine(args) {
    return this._fetch('api/machines/delete', 'post', args);
  }
  updateMachine(args) {
    return this._fetch('api/machines/update', 'post', args);
  }
}
exports.Machines = Machines;