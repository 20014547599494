"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _address = require("./address");
Object.keys(_address).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _address[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _address[key];
    }
  });
});
var _base = require("./base");
Object.keys(_base).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _base[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _base[key];
    }
  });
});
var _customer = require("./customer");
Object.keys(_customer).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _customer[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _customer[key];
    }
  });
});
var _machine = require("./machine");
Object.keys(_machine).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _machine[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _machine[key];
    }
  });
});
var _order = require("./order");
Object.keys(_order).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _order[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _order[key];
    }
  });
});
var _payment = require("./payment");
Object.keys(_payment).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _payment[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _payment[key];
    }
  });
});
var _product = require("./product");
Object.keys(_product).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _product[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _product[key];
    }
  });
});
var _search = require("./search");
Object.keys(_search).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _search[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _search[key];
    }
  });
});
var _shipping = require("./shipping");
Object.keys(_shipping).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _shipping[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _shipping[key];
    }
  });
});