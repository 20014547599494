import React from 'react'
import { IconsProps } from 'msp-integrations'

const WishlistIcon = (props: IconsProps) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='-1 -1 24 24'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d="M16.397 0c-2.13 0-4.072 1.133-5.178 2.913C10.113 1.133 8.172 0 6.041 0 2.724 0 0 2.724 0 6.122v.108c0 3.83 1.51 7.335 4.666 10.734 2.238 2.427 5.393 5.043 6.58 5.043 1.187 0 4.315-2.616 6.58-5.043 3.129-3.398 4.64-6.904 4.64-10.734v-.108C22.465 2.724 19.74 0 16.396 0Zm5.26 6.23c0 3.587-1.457 6.93-4.45 10.167-2.59 2.778-5.34 4.8-5.96 4.8-.621 0-3.399-1.995-5.961-4.8C2.238 13.161.809 9.844.809 6.23v-.162A5.266 5.266 0 0 1 6.07.809a5.268 5.268 0 0 1 4.8 3.128l.377.837.378-.837a5.268 5.268 0 0 1 4.8-3.128 5.266 5.266 0 0 1 5.26 5.26v.16h-.028Z"/>
    </svg>
  )
}

export default WishlistIcon
