"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCountryData = exports.GetPostalCodeRegexByCountryCode = exports.GetPaymentConfigByCountryCode = exports.GetCountryConfigByCountryCode = void 0;
var _react = require("react");
var _LanguageContext = require("../contexts/LanguageContext");
/**
 * Custom React hook to get the country data from site-countries endpoint
 * @returns Localization
 */
const useCountryData = () => (0, _react.useContext)(_LanguageContext.LanguageContext);

/**
 * Custom React hook to get the country data from site-countries endpoint
 * @returns Localization.countries.config | undefined
 */
exports.useCountryData = useCountryData;
const GetCountryConfigByCountryCode = (i18n, countryCode) => {
  var _i18n$countries;
  return i18n === null || i18n === void 0 ? void 0 : (_i18n$countries = i18n.countries) === null || _i18n$countries === void 0 ? void 0 : _i18n$countries.find(country => country.locales.find(lang => {
    const localeArray = lang.locale.split('_');
    if (localeArray[1] && localeArray[1] === countryCode) {
      return country.config;
    }
    return undefined;
  }));
};

/**
 * Custom React hook to get the country data from site-countries endpoint
 * @returns string | undefined
 */
exports.GetCountryConfigByCountryCode = GetCountryConfigByCountryCode;
const GetPostalCodeRegexByCountryCode = (i18n, countryCode) => {
  var _countryConfigData$co, _countryConfigData$co2, _countryConfigData$co3, _countryConfigData$co4;
  const countryConfigData = GetCountryConfigByCountryCode(i18n, countryCode);
  return countryConfigData && (countryConfigData === null || countryConfigData === void 0 ? void 0 : (_countryConfigData$co = countryConfigData.config) === null || _countryConfigData$co === void 0 ? void 0 : (_countryConfigData$co2 = _countryConfigData$co.forms) === null || _countryConfigData$co2 === void 0 ? void 0 : (_countryConfigData$co3 = _countryConfigData$co2.address) === null || _countryConfigData$co3 === void 0 ? void 0 : (_countryConfigData$co4 = _countryConfigData$co3.postalCode) === null || _countryConfigData$co4 === void 0 ? void 0 : _countryConfigData$co4.regexp) || undefined;
};

/**
 * Custom function to get the payment data from site-countries endpoint
 * @returns string | undefined
 */
exports.GetPostalCodeRegexByCountryCode = GetPostalCodeRegexByCountryCode;
const GetPaymentConfigByCountryCode = (i18n, countryCode) => {
  const countryConfigData = GetCountryConfigByCountryCode(i18n, countryCode);
  return countryConfigData && (countryConfigData === null || countryConfigData === void 0 ? void 0 : countryConfigData.payment) || undefined;
};
exports.GetPaymentConfigByCountryCode = GetPaymentConfigByCountryCode;