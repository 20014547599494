"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSearch = void 0;
var _react = require("react");
var _contexts = require("../contexts");
const useSearch = searchParams => {
  const api = (0, _contexts.useCommerceAPI)();
  const [search, setSearch] = (0, _react.useState)();
  const [isLoading, setIsLoading] = (0, _react.useState)(true);
  (0, _react.useEffect)(() => {
    // @ts-ignore
    api.shopperSearch.productSearch({
      parameters: searchParams
    }).then(res => {
      setSearch(res);
    }).catch(err => console.error(err)).finally(() => setIsLoading(false));
  }, []);
  return {
    search,
    isLoading
  };
};
exports.useSearch = useSearch;