import { CommerceBoxProductProps, EventsEmitter, Events } from 'msp-components'

type BasketBonusDiscount = {
  bonusDiscountLineItems?: Array<{ c_hasBonusProductLeft?: boolean }>
}

const dispatchChoiceOfBonusModal = (basket: BasketBonusDiscount) => {
  const bonusLineItems = basket.bonusDiscountLineItems || []

  if (bonusLineItems.length > 0) {
    const bonusLineItem = bonusLineItems.find(
      (lineItem) => lineItem?.c_hasBonusProductLeft === true
    )
    if (bonusLineItem) {
      EventsEmitter.dispatch(
        Events.OPEN_CHOICE_OF_BONUS_PRODUCTS_MODAL,
        bonusLineItem
      )
    }
  }
}

const validateProduct = (
  product?: CommerceBoxProductProps | string,
  noVariants: boolean = false
) => {
  if (!product) return {}
  if (typeof product === 'string') return product
  if (product && noVariants) return product

  const {
    id,
    price,
    currency,
    c_inventory,
    stepQuantity,
    primaryCategoryId,
    slugUrl,
    minOrderQuantity
  } = product || {}
  if (
    id &&
    price &&
    currency &&
    c_inventory &&
    stepQuantity &&
    primaryCategoryId &&
    slugUrl &&
    minOrderQuantity
  ) {
    return product || {}
  }
  // If we've some missing information, we'll do the call anyway
  return id
}

export { validateProduct, dispatchChoiceOfBonusModal }
