import { useIntl } from 'react-intl'
import { getAssetUrl } from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import {
  useProduct as originalUseProduct,
  OUT_OF_STOCK,
  UNFULFILLABLE
} from 'msp-integrations'

const useProduct = (item, isProductPartOfSet = false) => {
  const intl = useIntl()

  const product = originalUseProduct(item, isProductPartOfSet)
  const stockLevel = product?.c_inventory?.stockLevel || 0

  const isOutOfStock =
    !stockLevel ||
    (!product?.variant &&
      Object.keys(product?.variationParams).length ===
        product?.variationAttributes.length)
  const unfulfillable = stockLevel < product?.quantity
  const inventoryMessages = {
    [OUT_OF_STOCK]: intl.formatMessage({
      defaultMessage: 'Out of stock',
      id: 'use_product.message.out_of_stock'
    }),
    [UNFULFILLABLE]: intl.formatMessage(
      {
        defaultMessage: 'Only {stockLevel} left!',
        id: 'use_product.message.inventory_remaining'
      },
      { stockLevel }
    )
  }
  const showInventoryMessage =
    product?.variant && (isOutOfStock || unfulfillable)
  const inventoryMessage =
    (isOutOfStock && inventoryMessages[OUT_OF_STOCK]) ||
    (unfulfillable && inventoryMessages[UNFULFILLABLE])

  const variationAttributes = (product?.variationAttributes || []).map(
    (variationAttribute) => {
      let values = variationAttribute.values.map((variationAttributeValue) => {
        let image
        if (variationAttribute.id === 'motivGiftcard') {
          image = {
            sm: getAssetUrl(
              `static/img/pdp/giftcards/${variationAttributeValue.value}/90x58.jpg`
            ),
            md: getAssetUrl(
              `static/img/pdp/giftcards/${variationAttributeValue.value}/135x87.jpg`
            ),
            xl: getAssetUrl(
              `static/img/pdp/giftcards/${variationAttributeValue.value}/90x58.jpg`
            )
          }
        }
        return {
          ...variationAttributeValue,
          image
        }
      })
      return {
        ...variationAttribute,
        values
      }
    }
  )

  return {
    ...product,
    showInventoryMessage,
    inventoryMessage,
    variationAttributes
  }
}

export default useProduct
