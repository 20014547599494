import { OverlayType, ContentPositionType } from './FullWidthIntro.types'
import * as S from './FullWidthIntro.styles'

export const getPosition = ({
  overlay,
  contentPosition
}: {
  overlay: OverlayType
  contentPosition: ContentPositionType
}) => {
  if (!contentPosition) return ''

  if (overlay === 'base' || overlay === 'hero') {
    return contentPosition === 'left' ? 'lg:left-[10%]' : 'lg:right-[10%]'
  }
  return ''
}

export const getOverlayStyle = (overlay: OverlayType) => {
  switch (overlay) {
    case 'base':
      return S.baseOverlay
    case 'hero':
      return S.heroOverlay
    case 'service':
      return S.serviceOverlay
    case 'about':
      return S.aboutOverlay
    default:
      return S.baseOverlay
  }
}
