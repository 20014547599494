"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OcapiShopperPassword = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _utils = require("./utils");
class OcapiShopperPassword {
  constructor(config) {
    this.fetch = (0, _utils.createOcapiFetch)(config);
  }
  sendPasswordResetEmail(...args) {
    var _this = this;
    return (0, _asyncToGenerator2.default)(function* () {
      const {
        body
      } = args[0];
      return yield _this.fetch('customers/password_reset', 'POST', args, 'sendPasswordResetEmail', body, true);
    })();
  }
}
exports.OcapiShopperPassword = OcapiShopperPassword;