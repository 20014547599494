"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OcapiProducts = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _utils = require("./utils");
class OcapiProducts {
  constructor(config) {
    this.fetch = (0, _utils.createOcapiFetch)(config);
  }
  getProduct(...args) {
    var _this = this;
    return (0, _asyncToGenerator2.default)(function* () {
      const required = ['productId'];
      let requiredParametersError = (0, _utils.checkRequiredParameters)(args[0], required);
      if (requiredParametersError) {
        return requiredParametersError;
      }
      const {
        productId
      } = args[0].parameters;
      // @ts-ignore
      return yield _this.fetch(`products/${productId}`, 'GET', args, 'getProduct');
    })();
  }
  getProductAvailability(...args) {
    var _this2 = this;
    return (0, _asyncToGenerator2.default)(function* () {
      const required = ['productId'];
      let requiredParametersError = (0, _utils.checkRequiredParameters)(args[0], required);
      if (requiredParametersError) {
        return requiredParametersError;
      }
      const {
        productId
      } = args[0].parameters;
      // @ts-ignore
      return yield _this2.fetch(`products/${productId}/availability`, 'GET', args, 'getProductAvailability');
    })();
  }
}
exports.OcapiProducts = OcapiProducts;