"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _WebToCase = _interopRequireDefault(require("./sfsc-api/WebToCase"));
var _createSfScFecth = require("../utils/createSfScFecth");
class SfScApiClient {
  constructor(config) {
    this._config = config;
    const fetch = (0, _createSfScFecth.createSfScFetch)(config);
    this.WebToCase = new _WebToCase.default(fetch);
  }
  getConfig() {
    return this._config;
  }
}
var _default = exports.default = SfScApiClient;