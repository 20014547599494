"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStores = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("react");
var _contexts = require("../contexts");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const useStores = () => {
  const api = (0, _contexts.useCommerceAPI)();
  const [stores, setStores] = (0, _react.useState)();
  const [isLoading, setIsLoading] = (0, _react.useState)(false);
  const getStores = location => {
    setIsLoading(true);
    // @ts-ignore
    api.shopperStores.getStores({
      parameters: _objectSpread(_objectSpread({}, location), {}, {
        max_distance: 100
      })
    }).then(res => {
      setStores(res);
    }).catch(err => console.error(err)).finally(() => setIsLoading(false));
  };
  return [stores, isLoading, getStores, () => {
    setStores(undefined);
  }];
};
exports.useStores = useStores;