"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useOrders = useOrders;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("react");
var _utils = require("@salesforce/pwa-kit-react-sdk/ssr/universal/utils");
var _useCustomer = require("../commerce-api/hooks/useCustomer");
var _Order = require("../common-models/Order");
const _excluded = ["data"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } // @ts-ignore
/**
 * A hook for managing orders state and actions
 * @returns {Object} Machines data and actions
 */
function useOrders() {
  const mounted = (0, _react.useRef)();
  const customer = (0, _useCustomer.useCustomer)();
  const [state, setState] = (0, _react.useState)({
    orders: [],
    orderIds: [],
    ordersById: {},
    paging: {
      limit: 10,
      offset: 0,
      total: 0
    },
    isLoading: true
  });

  // @ts-ignore
  const mergeState = (0, _react.useCallback)(data => {
    // If we become unmounted during an async call that results in updating state, we
    // skip the update to avoid React errors about setting state in unmounted components.
    if (!mounted.current) {
      return;
    }
    setState(_state => _objectSpread(_objectSpread({}, _state), data));
  });

  // We use this to track mounted state.
  (0, _react.useEffect)(() => {
    // @ts-ignore
    mounted.current = true;
    return () => {
      // @ts-ignore
      mounted.current = false;
    };
  }, []);
  const ctx = (0, _react.useMemo)(() => {
    return _objectSpread(_objectSpread({}, state), {}, {
      // Getter functions
      // Provides convenient access to various data points and derivations.
      // ----------------

      /**
       * Returns boolean value whether the customer has orders
       */
      get hasOrders() {
        return ctx.totalOrders > 0;
      },
      get productFallbackImage() {
        return (0, _utils.getAssetUrl)('static/img/fallback-product-image.png');
      },
      /**
       * Returns total customer orders
       */
      get totalOrders() {
        return ctx.orders.length;
      },
      /**
       * Returns boolean value whether the customer has more orders
       */
      get hasMoreOrders() {
        return ctx.paging && ctx.paging.total > ctx.paging.limit + ctx.paging.offset;
      },
      /**
       * Get customer orders
       * @param {Object} pagingOptions { offset = 0, limit = 10 }
       * @returns {Promise}
       */
      fetchOrders({
        offset = ctx.paging.offset,
        limit = ctx.paging.limit
      } = ctx.paging) {
        return (0, _asyncToGenerator2.default)(function* () {
          mergeState({
            isLoading: true
          });
          const _yield$customer$getCu = yield customer === null || customer === void 0 ? void 0 : customer.getCustomerOrders({
              offset,
              limit
            }),
            {
              data = []
            } = _yield$customer$getCu,
            paging = (0, _objectWithoutProperties2.default)(_yield$customer$getCu, _excluded);
          mergeState({
            paging,
            isLoading: false,
            orders: [...ctx.orders, ...data]
          });
        })();
      },
      /**
       * Get order by order number
       * @param {string} orderNo Order number
       * @returns {Promise}
       */
      fetchOrder(orderNo) {
        return (0, _asyncToGenerator2.default)(function* () {
          mergeState({
            isLoading: true
          });
          const data = yield customer === null || customer === void 0 ? void 0 : customer.getOrder(orderNo);
          const orderIndex = ctx.orders.findIndex(order => order.orderNo === data.orderNo);
          if (orderIndex > -1 && ctx.orders[orderIndex]) {
            ctx.orders[orderIndex] = data;
          }
          mergeState({
            orders: [...ctx.orders],
            isLoading: false
          });
        })();
      },
      /**
       * Convert raw js Object to OrderModel instance
       * @param {Object} order Raw order object
       * @returns {OrderModel} instance of OrderModel
       */
      convertToOrderModel(order) {
        return new _Order.Order(order);
      }
    });
  }, [state, mergeState]);
  return ctx;
}