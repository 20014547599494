import React from 'react'
import { IconsProps } from 'msp-integrations'

const MenuIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <path d="M2 4.5h20v1H2zm0 7h20v1H2zm0 7h20v1H2z" />
    </svg>
  )
}

export default MenuIcon
