import React from 'react'
import { IconsProps } from 'msp-integrations'

const PinterestIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 141.73 141.73'
    >
      <path d="M71.5 30.48c-22.3 0-40.37 18.08-40.37 40.37 0 17.1 10.64 31.73 25.66 37.61-.35-3.19-.67-8.11.14-11.59.73-3.15 4.73-20.07 4.73-20.07s-1.21-2.42-1.21-5.99c0-5.61 3.25-9.8 7.3-9.8 3.44 0 5.11 2.59 5.11 5.69 0 3.46-2.21 8.64-3.34 13.44-.95 4.02 2.02 7.3 5.98 7.3 7.18 0 12.69-7.57 12.69-18.49 0-9.67-6.95-16.43-16.87-16.43-11.49 0-18.23 8.62-18.23 17.52 0 3.47 1.34 7.19 3 9.21.33.4.38.75.28 1.16-.31 1.27-.99 4.02-1.12 4.58-.18.74-.59.9-1.35.54-5.04-2.35-8.2-9.72-8.2-15.64 0-12.74 9.25-24.43 26.68-24.43 14.01 0 24.89 9.98 24.89 23.32 0 13.91-8.77 25.11-20.95 25.11-4.09 0-7.94-2.12-9.25-4.64 0 0-2.02 7.71-2.52 9.6-.91 3.51-3.37 7.9-5.02 10.58 3.78 1.17 7.79 1.8 11.95 1.8 22.3 0 40.37-18.08 40.37-40.37.02-22.3-18.05-40.38-40.35-40.38z"/>
    </svg>
  )
}

export default PinterestIcon
