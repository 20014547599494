"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
class SfScApiWebToCase {
  constructor(fetch) {
    this._fetch = fetch;
  }
  send(args) {
    var _this = this;
    return (0, _asyncToGenerator2.default)(function* () {
      args.headers = _objectSpread(_objectSpread({}, args.headers), {}, {
        'Content-Type': 'application/x-www-form-urlencoded'
      });
      args.query = _objectSpread(_objectSpread({}, args.query), {}, {
        encoding: 'UTF-8'
      });
      return _this._fetch('servlet/servlet.WebToCase', 'POST', args, 'Send-WebToCase');
    })();
  }
}
var _default = exports.default = SfScApiWebToCase;