"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSfScApi = void 0;
var _react = _interopRequireDefault(require("react"));
var _IntegrationsProvider = require("../providers/IntegrationsProvider");
const useSfScApi = () => {
  return _react.default.useContext(_IntegrationsProvider.ApiContext);
};
exports.useSfScApi = useSfScApi;