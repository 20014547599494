"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.protectedRoute = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _util = require("./util");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const protectedRoute = (req, res, next) => {
  var _req$headers, _req$headers2, _req$headers2$authori;
  let token;
  if (req !== null && req !== void 0 && (_req$headers = req.headers) !== null && _req$headers !== void 0 && _req$headers.authorization && req !== null && req !== void 0 && (_req$headers2 = req.headers) !== null && _req$headers2 !== void 0 && (_req$headers2$authori = _req$headers2.authorization) !== null && _req$headers2$authori !== void 0 && _req$headers2$authori.startsWith('Bearer')) {
    try {
      token = req.headers.authorization.split(' ')[1];
      const decoded = (0, _util.parseSlasJWT)(token);
      if (!(decoded !== null && decoded !== void 0 && decoded.usid)) throw 'Wrong User!';
      //@ts-ignore
      req.auth = _objectSpread({}, decoded);
      next();
    } catch (error) {
      console.log(error);
      res.status(401);
      throw new Error('Not authorized');
    }
  }
  if (!token) {
    res === null || res === void 0 ? void 0 : res.status(401);
    throw new Error('Not authorized, no token');
  }
};
exports.protectedRoute = protectedRoute;