import React from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useServerContext } from '@salesforce/pwa-kit-react-sdk/ssr/universal/hooks'
import { isServer, useConfig } from 'msp-integrations'
import PageLayout from '@/components/cms/page-layout'
import { Headline, MainWrapper } from 'msp-components'
import Seo from '@/components/seo'

const MaintenancePage = () => {
  const { formatMessage } = useIntl()
  const {
    theme: { font }
  } = useConfig()
  const messages = defineMessages({
    seoTitle: {
      id: 'msp.maintenance.seo.title',
      defaultMessage: 'Maintenance'
    },
    title: {
      id: 'msp.maintenance.title',
      defaultMessage:
        "Sorry for the inconvenience!{br}We are currently performing a small upgrade to make your next visit with us even tastier. We'll be back soon to serve you our most delicious teas as usual! In the meantime, we're putting the finishing touches on our store. Stay calm and drink tea, the Avoury TEAm is on its way back to you!"
    },
    descr: {
      id: 'msp.maintenance.descr',
      defaultMessage:
        'If you have any questions, feel free to contact us free of charge between 7am and 10pm on 00800 7000 6000 or by email at support@avoury.com.'
    }
  })

  return (
    <>
      <Seo
        title={formatMessage(messages.seoTitle)}
        description={formatMessage(messages.descr)}
      />
      <MainWrapper className='not-found-container' fullWidth={true}>
        <div className='flex h-full flex-row'>
          <div className='lg:w-full'></div>
          <div className='flex h-full min-h-[808px] w-full flex-col justify-center p-8 max-lg:bg-black/50'>
            <Headline
              tag='h1'
              className='text-24 leading-36 pt-4 text-white sm:max-w-[700px]'
            >
              <FormattedMessage {...messages.title} values={{ br: <br /> }} />
            </Headline>
            <p
              className={`${font.regular} text-20 md:text-16 pt-6 font-light text-white`}
            >
              <p>{formatMessage(messages.descr)}</p>
            </p>
          </div>
        </div>
      </MainWrapper>
    </>
  )
}

const MaintenancePageWrapper = (props) => {
  const { res } = useServerContext()

  if (isServer) {
    res.status(503)
  }

  return (
    <PageLayout
      {...Object.assign({ ChildPage: MaintenancePage }, props)}
      isMaintenancePage={true}
    />
  )
}

export default MaintenancePageWrapper
